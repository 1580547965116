import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Logo, LogoutIcon, MenuIcon, MobileLogo } from "../../assests/images";
import { WarningModal } from "./warning-modal";
import { AppRoutings } from "../../utility/enums/app-routings";
import { IMenuPermissions } from "../../utility/interfaces/account/user-details";
import { LightDarkButton } from "./light-dark-button";
import tokenManager from "../../utility/auth-guards/token-manager";
import { UserContext } from "../../contexts/user-context";
import { ChatCountContext } from "../../contexts/chat-count-context";

const HeaderType2: React.FC = () => {
  const [isLogoutWarning, setIsLogoutWarning] = React.useState(false);

  // use context
  const contextUser = useContext(UserContext);
  const chatCount = useContext(ChatCountContext);

  // Page level local variables
  const navigate = useNavigate();
  const menuPermissions: IMenuPermissions[] = [
    {
      title: "Dashboard",
      url: AppRoutings.Dashboard,
    },
    {
      title: "Chat",
      url: AppRoutings.Chat,
    },
    {
      title: "Profile",
      url: AppRoutings.Profile,
    },
  ];
  return (
    <>
      <AppBar position="static" className="header">
        <Toolbar disableGutters sx={{ flexWrap: "wrap" }}>
          <Box className="header-container">
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <IconButton
                size="large"
                onClick={() => document.body.classList.toggle("sidebar-toggle")}
                sx={{ display: { xs: "flex", sm: "none" } }}
                className="menu-btn"
                disableRipple
                disableFocusRipple
              >
                <img src={MenuIcon} alt="menu" />
              </IconButton>
              <Tooltip title="Doctors To You">
                <a
                  onClick={() => navigate(AppRoutings.Root)}
                  className="header-logo"
                >
                  <img src={Logo} alt="Doctors To You" className="desktop" />
                  <img
                    src={MobileLogo}
                    alt="Doctors To You"
                    className="mobile"
                  />
                </a>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0 }} className="header-content">
              <Typography
                variant="h6"
                className="greetings"
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                Welcome&nbsp;
                {contextUser ? (
                  <span>{`${contextUser.firstName} ${contextUser.lastName}`}</span>
                ) : (
                  <span>{`${"firstName"} ${"lastName"}`}</span>
                )}
              </Typography>
              <Tooltip title="Logout">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsLogoutWarning(true);
                  }}
                  className="logout-btn"
                >
                  <img src={LogoutIcon} alt="logout" />
                  <span>Logout</span>
                </Button>
              </Tooltip>
              <LightDarkButton />
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              minWidth: "100%",
            }}
            className="menu-container"
          >
            {menuPermissions.map((parentMenu, index) => {
              return (
                <div key={index}>
                  <>
                    <Button
                      variant="text"
                      className={
                        window.location.pathname.includes(parentMenu.url || "")
                          ? "menu-item active"
                          : "menu-item"
                      }
                      onClick={() => {
                        navigate(parentMenu.url ? parentMenu.url : "");
                      }}
                    >
                      <strong>
                        {parentMenu.title}
                        {parentMenu.title === "Chat" &&
                          chatCount > 0 &&
                          `(${chatCount})`}
                      </strong>
                      <span className="link-item">
                        {parentMenu.title}
                        {parentMenu.title === "Chat" &&
                          chatCount > 0 &&
                          `(${chatCount})`}
                      </span>
                    </Button>
                  </>
                </div>
              );
            })}
          </Box>
        </Toolbar>
        <nav className="sidebar">
          <Typography
            variant="h6"
            className="greetings"
            sx={{ display: "flex" }}
          >
            Welcome,&nbsp;
            <span>{`${"firstName"} ${"lastName"}`}</span>
          </Typography>
          <List className="sidebar-menu">
            {menuPermissions.map((parentMenu, index) => {
              return (
                <div key={index}>
                  <>
                    <ListItemButton disableRipple disableTouchRipple>
                      <Link
                        onClick={() => {
                          document.body.classList.toggle("sidebar-toggle");
                        }}
                        className={
                          window.location.pathname.includes(
                            parentMenu.url || ""
                          )
                            ? "active-menu"
                            : "default-menu"
                        }
                        to={parentMenu.url ? parentMenu.url : ""}
                      >
                        {parentMenu.title}
                        {parentMenu.title === "Chat" &&
                          chatCount > 0 &&
                          `(${chatCount})`}
                      </Link>
                    </ListItemButton>
                  </>
                </div>
              );
            })}
          </List>
        </nav>
      </AppBar>
      <WarningModal
        isModalOpen={isLogoutWarning}
        handleOnClickCloseModal={() => setIsLogoutWarning(false)}
        handleOnClickOk={() => tokenManager.clearSession()}
        warningMessage="Are you sure, you want to logout from here?"
        title="Logout"
        okButtonText="Logout"
        closeButtonText="Cancel"
      />
    </>
  );
};

export default HeaderType2;
