import * as yup from "yup";
import { phoneNumberRegExp } from "../helpers/regular-expressions";

export const profileFormSchema = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  email: yup
    .string()
    .email("Email is not valid.")
    .required("Email is required."),
  // phoneNumber: yup
  //   .string()
  //   .required("Mobile/Landline number is required.")
  //   .matches(phoneNumberRegExp, "Enter valid phone number."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipCode: yup.string().required("Zip code is required."),
});
