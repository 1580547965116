import { Box, Button, Modal, Typography } from "@mui/material";
import { PasswordReset, CrossRedIcon } from "../../assests/images";
import {
	SuccessErrorModalContext,
	SuccessErrorModalDispatchContext,
} from "../../contexts/success-error-context";
import React, { useContext } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 556,
	borderRadius: "10px",
	bgcolor: "background.paper",
	padding: "76px 74px 70px",
	textAlign: "center",
	"@media (max-width:599px)": {
		width: "calc(100% - 30px)",
		padding: "30px",
	},
};

export function SuccessErrorModal() {
	const successErrorContext = useContext(SuccessErrorModalContext);
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const handleClickClose = () => {
		setSuccessErrorContext({
			isSuccessErrorOpen: false,
			title: "",
			message: "",
			isSuccess: true,
		});
		if (successErrorContext && successErrorContext.handleOnClickOk)
			successErrorContext.handleOnClickOk();
	};

	return (
		<>
			<Modal
				open={successErrorContext?.isSuccessErrorOpen}
				onClose={handleClickClose}
				className="reset-modal"
			>
				<Box sx={style}>
					<img
						src={successErrorContext?.isSuccess ? PasswordReset : CrossRedIcon}
						alt="Done"
						className="reset-img"
					/>
					<Typography variant="h2">{successErrorContext?.title}</Typography>
					<Typography variant="body1">
						{successErrorContext?.message}
					</Typography>
					<Button onClick={handleClickClose} variant="contained" title="close">
						Ok
					</Button>
				</Box>
			</Modal>
		</>
	);
}
