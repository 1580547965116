import {
  Badge,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { IChatListView } from "../../utility/interfaces/common/chat-box-info";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type ComponentProps = {
  chatList: IChatListView[];
  handleOpenChatClick: any;
};

export function ChatList(props: ComponentProps) {
  // Extract props
  const { chatList, handleOpenChatClick } = props;
  const [readCountUpdated, setReadCountUpdated] = useState(false);

  return (
    <>
      <Box>
        <TableContainer>
          <Table>
            <TableBody>
              {chatList.map((chat, index) => {
                let readCount = chat.readCount;
                return (
                  <TableRow
                    key={index}
                    className="bg-white"
                    style={{
                      borderTop: index === 0 ? "1px solid #e0e0e0" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      readCount = 0;
                      setReadCountUpdated(!readCountUpdated);
                      handleOpenChatClick(index);
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="wrap"
                      style={{
                        width: "90%",
                      }}
                    >
                      {chat.groupName !== null ? (
                        <Box>
                          <Typography variant="h5">{chat.groupName}</Typography>
                        </Box>
                      ) : (
                        <>
                          <Box>
                            <Typography variant="h5">
                              {chat.physicianId != null
                                ? chat.physicianName
                                : chat.adminId
                                ? "Admin"
                                : chat.name}
                            </Typography>
                          </Box>
                        </>
                      )}
                      <Box sx={{ fontSize: "13px" }}>
                        {chat.confirmationNumber || ""}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Badge badgeContent={readCount} color="error">
                        <IconButton
                          onClick={() => {
                            readCount = 0;
                            setReadCountUpdated(!readCountUpdated);
                            handleOpenChatClick(index);
                          }}
                        >
                          <ChevronRightIcon></ChevronRightIcon>
                        </IconButton>
                      </Badge>
                    </TableCell>
                  </TableRow>
                );
              })}
              {chatList.length === 0 && (
                <Typography variant="h5">No Chats Found</Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
