import Cookies from "js-cookie";
import { Constants } from "../enums/constants";
import authService from "../../services/auth-service";
import { AppRoutings } from "../enums/app-routings";
import { publicIpv4 } from "public-ip";
import { IMenu, IUserDetails } from "../interfaces";
import { APPLICATION_TYPE, ENVIRONMENT } from "../../config";
import { showLoader, createCommonAPICall } from "../helpers";

const getNewDtySecret = async () => {
  const data = await createCommonAPICall({
    apiService: authService.generateDefaultToken,
    showSuccessMessage: false,
    showErrorMessage: false,
    isHideLoader: false,
  });
  if (data && data.data) return data.data.dtySecret;
  return "";
};

const getDeviceIpAddress = async () => {
  const ip = await publicIpv4();
  return ip;
};
const clearCookiesAndLocalStorage = () => {
  Cookies.remove(ENVIRONMENT + APPLICATION_TYPE + Constants.DtySecretKey);
  Cookies.remove(ENVIRONMENT + APPLICATION_TYPE + Constants.AppTokenKey);
  Cookies.remove(ENVIRONMENT + APPLICATION_TYPE + Constants.UserDetails);
  localStorage.removeItem(ENVIRONMENT + APPLICATION_TYPE + Constants.ChatCount);
};

const clearSession = (returnUrl?: string) => {
  showLoader();
  clearCookiesAndLocalStorage();
  if (window) {
    const url = returnUrl
      ? `${AppRoutings.Root}?back=${returnUrl}`
      : AppRoutings.Root;
    window.location.replace(url);
  }
};

const getToken = (): string | undefined => {
  const encryptToken = Cookies.get(
    ENVIRONMENT + APPLICATION_TYPE + Constants.AppTokenKey
  );
  const token = doEncryptDecrypt(false, encryptToken);
  const sessionToken = token?.slice(1, -1);
  if (sessionToken) return sessionToken;
  else {
    clearCookiesAndLocalStorage();
    return undefined;
  }
};

const getDtySecret = (): string => {
  const encryptSecret = Cookies.get(
    ENVIRONMENT + APPLICATION_TYPE + Constants.DtySecretKey
  );
  return doEncryptDecrypt(false, encryptSecret).slice(1, -1);
};

const getChatCount = (): number => {
  const encryptedChatCount = localStorage.getItem(
    ENVIRONMENT + APPLICATION_TYPE + Constants.ChatCount
  );
  const chatCount = parseInt(doEncryptDecrypt(false, encryptedChatCount));
  console.log(chatCount);
  return chatCount;
};

const setChatCount = (chatCount: number) => {
  localStorage.setItem(
    ENVIRONMENT + APPLICATION_TYPE + Constants.ChatCount,
    doEncryptDecrypt(true, chatCount)
  );
};

const setAuthorization = (
  dtySecret: string,
  jwtToken: string,
  userDetails: string
): void => {
  Cookies.set(
    ENVIRONMENT + APPLICATION_TYPE + Constants.DtySecretKey,
    doEncryptDecrypt(true, dtySecret),
    {
      expires: 30,
    }
  );
  Cookies.set(
    ENVIRONMENT + APPLICATION_TYPE + Constants.AppTokenKey,
    doEncryptDecrypt(true, "bearer " + jwtToken),
    {
      expires: 30,
    }
  );
  Cookies.set(
    ENVIRONMENT + APPLICATION_TYPE + Constants.UserDetails,
    doEncryptDecrypt(true, userDetails),
    {
      expires: 30,
    }
  );
};
const getUserDetails = (): IUserDetails | undefined => {
  const userDetailString = doEncryptDecrypt(
    false,
    Cookies.get(ENVIRONMENT + APPLICATION_TYPE + Constants.UserDetails)
  );
  if (userDetailString) {
    const userDetails: IUserDetails = JSON.parse(
      JSON.parse(userDetailString ? userDetailString : "")
    );
    userDetails.menuPermissions = "";
    return userDetails;
  } else return undefined;
};

const doEncryptDecrypt = (isEncrypt: boolean, data: any): string => {
  if (data) {
    const CryptoJS = require("crypto-js");
    if (isEncrypt === true) {
      // Encrypt
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        Constants.LocalAppEncryptkey
      ).toString();
      return ciphertext;
    }
    // Decrypt
    const bytes = CryptoJS.AES.decrypt(data, Constants.LocalAppEncryptkey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
  return "";
};

export default {
  getDtySecret,
  setAuthorization,
  getToken,
  clearSession,
  getNewDtySecret,
  getDeviceIpAddress,
  getUserDetails,
  clearCookiesAndLocalStorage,
  getChatCount,
  setChatCount,
};
