import {
  Modal,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Button,
  TextField,
} from "@mui/material";
import { CloseWhiteIcon } from "../../assests/images";
import { commonModalStyle } from "../../utility/helpers";
import { useState } from "react";
import { cancelAgreementFormSchema } from "../../utility/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

type ComponentProps = {
  cancelAgreementModalOpen: boolean;
  handleCancelAgreementModalClose: any;
  patientFullName: string;
  handleClickCancel: any;
};

export function CancelAgreementModal(props: ComponentProps) {
  // use State
  const [notes, setNotes] = useState("");

  // handle events
  const handleConfirm = () => {
    handleClickCancel(notes);
  };

  //Extract props
  const {
    cancelAgreementModalOpen,
    handleCancelAgreementModalClose,
    patientFullName,
    handleClickCancel,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cancelAgreementFormSchema),
  });

  return (
    <>
      <Modal
        open={cancelAgreementModalOpen}
        onClose={handleCancelAgreementModalClose}
        className="send-modal"
      >
        <Box sx={commonModalStyle}>
          <Box className="modal-header">
            <Typography variant="h5">Cancel Confirmation</Typography>
            <IconButton onClick={handleCancelAgreementModalClose}>
              <img src={CloseWhiteIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <div>
              <Typography variant="h5" marginBottom={"15px"}>
                {patientFullName},
              </Typography>
              <TextField
                {...register("notes")}
                error={errors.notes?.message ? true : false}
                helperText={errors.notes?.message?.toString()}
                name="notes"
                id="notes"
                label="Please provide a brief explanation for your cancelation"
                value={notes}
                multiline
                maxRows={1}
                inputProps={{
                  style: { minHeight: "88px" },
                }}
                fullWidth
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <Tooltip title="Confirm">
                <Button
                  variant="contained"
                  onClick={handleSubmit(handleConfirm)}
                >
                  Confirm
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setNotes("");
                    handleCancelAgreementModalClose();
                  }}
                >
                  Cancel
                </Button>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
