import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LocationIcon } from "../../assests/images";
import { IProfileInfo } from "../../utility/interfaces/profile/profile-info";
import { profileFormSchema } from "../../utility/validations/profile";
import CustomDatePicker from "../common/custom-date-picker";
import { createCommonAPICall } from "../../utility/helpers";
import dashboardService from "../../services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import moment from "moment";
import CustomIntlTelInput from "../common/custom-intl-tel-input";

const ProfileForm = () => {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // useStates
  const [formValues, setFormValues] = useState<IProfileInfo>({
    userId: 0,
    firstName: "",
    lastName: "",
    birthDate: new Date().toString(),
    email: "",
    phoneNumber: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    isLandLineNumber: false,
  });
  const [actualFormValues, setActualFormValues] = useState<IProfileInfo>({
    userId: 0,
    firstName: "",
    lastName: "",
    birthDate: new Date().toString(),
    email: "",
    phoneNumber: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    isLandLineNumber: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [rerender, setRerender] = useState(0);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  // Yup resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: formValues,
    resolver: yupResolver(profileFormSchema),
  });

  // Handled events or functions
  const onChangeFormVlaues = (name: string, value: any) => {
    setFormValues({ ...formValues, [name]: value });
  };
  const handleOnSave = async () => {
    console.log("save", formValues);
    const data = await createCommonAPICall({
      requestBody: formValues,
      apiService: dashboardService.updateCareProfile,
      showErrorMessage: true,
      showSuccessMessage: true,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      console.log(data);
      setIsEdit(false);
      getProfileInfo();
    }
  };

  const getProfileInfo = async () => {
    const data = await createCommonAPICall({
      apiService: dashboardService.getCreateCareProfile,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      console.log(data);
      setFormValues({
        ...data.data.profile,
        birthDate: new Date(data.data.profile.birthDate),
      });
      setActualFormValues({
        ...data.data.profile,
        birthDate: new Date(data.data.profile.birthDate),
      });
      setRerender(1);
    }
  };

  const handleOnClickOpenMap = () => {
    let address = `${formValues.street} ${formValues.city} ${formValues.state} ${formValues.zipCode}`;
    const url = `http://www.google.com/maps?q=${address}`;
    window.open(url, "_blank");
  };

  const handlePhoneNumberChange = (
    isValid: any,
    phoneNumber: any,
    countryData: any
  ) => {
    setIsPhoneNumberValid(isValid);
    let formattedPhoneNumber =
      phoneNumber != ""
        ? phoneNumber.includes("+")
          ? phoneNumber
          : `+${countryData.dialCode} ${phoneNumber}`
        : "";
    onChangeFormVlaues("phoneNumber", formattedPhoneNumber);
  };

  // useEffects
  useEffect(() => {
    const firstElement = document.getElementById("firstName");
    firstElement?.focus();
    getProfileInfo();
  }, []);

  return (
    <>
      <Typography variant="h4">General Information</Typography>
      <div>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!isEdit}
              {...register("firstName")}
              name="firstName"
              id="firstName"
              label="First Name"
              value={formValues.firstName}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
              error={errors?.firstName?.message ? true : false}
              helperText={errors?.firstName?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!isEdit}
              {...register("lastName")}
              error={errors?.lastName?.message ? true : false}
              helperText={errors?.lastName?.message?.toString()}
              name="lastName"
              id="lastName"
              label="Last Name"
              value={formValues.lastName}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              disabled={!isEdit}
              label="Date Of Birth"
              name="birthDate"
              value={formValues.birthDate}
              onChange={onChangeFormVlaues}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h4"> Contact Information</Typography>
        <Grid container columnSpacing={{ xs: 2, lg: 3 }}>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="isLandLineNumber-label">Type</InputLabel>
              <Select
                disabled={!isEdit}
                labelId="isLandLineNumber-label"
                id="isLandLineNumber"
                value={formValues.isLandLineNumber ? "landLine" : "mobile"}
                name="isLandLineNumber"
                label="Type"
                onChange={(e) => {
                  onChangeFormVlaues(
                    "isLandLineNumber",
                    e.target.value === "landLine"
                  );
                }}
              >
                <MenuItem value={"mobile"}>Mobile</MenuItem>
                <MenuItem value={"landLine"}>Landline</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            {rerender > 0 && (
              <CustomIntlTelInput
                disabled={!isEdit}
                isPhoneNumberValid={isPhoneNumberValid}
                onChange={handlePhoneNumberChange}
                onBlur={() => {}}
                value={formValues.phoneNumber}
                name="phoneNumber"
              />
            )}

            {/* <TextField
              disabled={!isEdit}
              {...register("phoneNumber")}
              error={errors?.phoneNumber?.message ? true : false}
              helperText={errors?.phoneNumber?.message?.toString()}
              name="phoneNumber"
              id="phoneNumber"
              label={
                formValues.isLandLineNumber
                  ? "Landline Number"
                  : "Mobile Number"
              }
              value={formValues.phoneNumber}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            /> */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              disabled={true}
              {...register("email")}
              error={errors?.email?.message ? true : false}
              helperText={errors?.email?.message?.toString()}
              name="email"
              id="email"
              label="Email"
              value={formValues.email}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h4">Location Information</Typography>
        <Grid container columnSpacing={{ xs: 2, lg: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!isEdit}
              {...register("street")}
              error={errors?.street?.message ? true : false}
              helperText={errors?.street?.message?.toString()}
              name="street"
              id="street"
              label="Street"
              value={formValues.street}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!isEdit}
              {...register("city")}
              error={errors?.city?.message ? true : false}
              helperText={errors?.city?.message?.toString()}
              name="city"
              id="city"
              label="City"
              value={formValues.city}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!isEdit}
              {...register("state")}
              error={errors?.state?.message ? true : false}
              helperText={errors?.state?.message?.toString()}
              name="state"
              id="state"
              label="State"
              value={formValues.state}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={7} sm={4}>
            <TextField
              disabled={!isEdit}
              {...register("zipCode")}
              error={errors?.zipCode?.message ? true : false}
              helperText={errors?.zipCode?.message?.toString()}
              name="zipCode"
              id="zipCode"
              label="Zip Code"
              value={formValues.zipCode}
              fullWidth
              onChange={(e) =>
                onChangeFormVlaues(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={5} sm={2}>
            <div className="verify-btn-group">
              <Button variant="outlined" onClick={handleOnClickOpenMap}>
                <img src={LocationIcon} alt="location" />
                Map
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="request-btn-group">
        {!isEdit ? (
          <Tooltip title="Click Here To Edit">
            <Button
              variant="contained"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit
            </Button>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Click Here To Save">
              <Button
                variant="contained"
                onClick={() => {
                  if (!isPhoneNumberValid || formValues.phoneNumber === "") {
                    setIsPhoneNumberValid(false);
                    handleSubmit(() => {})();
                  } else {
                    handleSubmit(handleOnSave)();
                  }
                }}
              >
                Save
              </Button>
            </Tooltip>
            <Tooltip title="Click Here To Cancel">
              <Button
                variant="outlined"
                onClick={() => {
                  setIsEdit(false);
                  setFormValues(actualFormValues);
                }}
              >
                Cancel
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};

export default ProfileForm;
