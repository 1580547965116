import { Box, Button, Modal, Typography } from "@mui/material";
import { WarningIcon } from "../../assests/images";

type ComponentProps = {
  isModalOpen: boolean;
  handleModalClose: any;
  handleSkipClick: any;
  isReturningPatient: boolean;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 556,
  borderRadius: "10px",
  bgcolor: "background.paper",
  padding: "76px 74px 70px",
  textAlign: "center",
  "@media (max-width:599px)": {
    width: "calc(100% - 30px)",
    padding: "30px",
  },
};

export const SkipVideoMessageModal = (props: ComponentProps) => {
  const { handleModalClose, handleSkipClick, isModalOpen, isReturningPatient } =
    props;

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        className="reset-modal"
      >
        <Box sx={style}>
          <img src={WarningIcon} alt="Done" className="reset-img" />
          <Typography variant="h2">Information</Typography>
          <Typography variant="body1">
            To Accept the agreement you have to watch whole video once!
          </Typography>
          {isReturningPatient && (
            <Typography variant="subtitle2">
              As you are a returning patient, you can skip the video if you
              want.
            </Typography>
          )}
          <div className="warning-modal-btn-group">
            {isReturningPatient ? (
              <>
                <Button variant="outlined" onClick={handleSkipClick}>
                  Skip and Agree
                </Button>
                <Button variant="outlined" onClick={handleModalClose}>
                  Close
                </Button>
              </>
            ) : (
              <Button variant="outlined" onClick={handleModalClose}>
                Ok
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};
