import { createTheme } from "@mui/material/styles";
declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		laptop: true;
	}
}

let myCustomTheme = createTheme({
	// custom breakpounts for mobile-view
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			laptop: 1361,
			xl: 1536,
		},
	},

	palette: {
		primary: {
			main: "#00BCE7",
		},
		text: {
			primary: "#333333",
			secondary: "#666666",
		},
	},
	spacing: 8,
	// Typography
	typography: {
		fontFamily: "Poppins,sans-serif",
		h1: {
			fontSize: 36,
			lineHeight: "54px",
			"@media(max-width:1535px)": {
				fontSize: 30,
				lineHeight: "45px",
			},
			"@media(max-width:1199px)": {
				fontSize: 26,
				lineHeight: "34px",
			},
		},
		h2: {
			fontSize: 24,
			lineHeight: "26px",
			"@media(max-width:599px)": {
				fontSize: 20,
				lineHeight: "26px",
			},
		},
		h4: {
			fontSize: 20,
			lineHeight: "30px",
			"@media(max-width:599px)": {
				fontSize: 16,
				lineHeight: "22px",
			},
		},
		h5: {
			fontSize: 18,
			lineHeight: "26px",
			"@media(max-width:599px)": {
				fontSize: 14,
				lineHeight: "20px",
			},
		},
		h6: {
			fontSize: 16,
			lineHeight: "24px",
			"@media(max-width:599px)": {
				fontSize: 14,
				lineHeight: "20px",
			},
		},
		body1: {
			fontSize: 14,
			lineHeight: "26px",
		},
	},
});

myCustomTheme = createTheme(myCustomTheme, {
	components: {
		MuiInputBase: {
			styleOverrides: {
				root: {
					display: "flex",
					fontSize: "15px",
					lineHeight: "26px",
				},
				input: {
					caretColor: [myCustomTheme.palette.primary.main],
					"::placeholder": {
						color: [myCustomTheme.palette.text.secondary],
						opacity: 1,
					},
				},
				MuiInputAdornment: {
					"& .MuiButtonBase-root.MuiIconButton-root": {
						padding: "12px",
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: "15px",
					lineHeight: "26px",
					color: [myCustomTheme.palette.text.secondary],
					transform: "translate(16px, 14px)",

					"&.Mui-focused, &[data-shrink = true]": {
						transform: "translate(14px, 0) scale(1);",
						fontSize: "11px",
						lineHeight: "27px",
					},
					"&.Mui-focused": {
						color: [myCustomTheme.palette.primary.main],
					},
					"&.Mui-disabled": {
						color: [myCustomTheme.palette.text.secondary],
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					display: "flex",
					padding: "0",
					borderRadius: "6px",
					"& .MuiOutlinedInput-notchedOutline": {
						top: "-1px",
					},
					"& .MuiOutlinedInput-notchedOutline legend": {
						display: "none",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderWidth: "1px",
					},
					"&.Mui-disabled": {
						color: [myCustomTheme.palette.text.primary],
						background: "#F5F5F5",
					},
					"&:hover:not(.Mui-focused, .Mui-disabled, .Mui-error) .MuiOutlinedInput-notchedOutline":
						{ borderColor: [myCustomTheme.palette.text.secondary] },
				},

				input: {
					height: "26px",
					padding: "21px 16px 9px",
					borderColor: [myCustomTheme.palette.primary.main],
					borderRadius: "6px",
					"&.Mui-disabled": {
						"-webkit-text-fill-color": [myCustomTheme.palette.text.primary],
					},
					"&.MuiInputBase-inputAdornedEnd": {
						paddingRight: "0",
					},
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					marginBottom: "24px",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "capitalize",
					borderRadius: "6px",
					fontWeight: 400,
				},
				sizeSmall: {
					fontSize: 14,
					lineHeight: "20px",
				},
				sizeMedium: {
					fontSize: 16,
					lineHeight: "26px",
				},
				outlinedPrimary: {
					borderColor: "#00BCE7",
					"&:hover": {
						backgroundColor: "rgb(0 188 231 / 12%)",
					},
					"&.MuiButton-sizeMedium": {
						paddingTop: "10px",
						paddingBottom: "10px",
					},
					"&.MuiButton-sizeSmall": {
						padding: "11px 16px",
					},
					"&.Mui-disabled": {
						color: "rgba(0, 188, 231, 0.7)",
						border: " 1px solid rgba(0, 188, 231, 0.5)",
					},
				},
				containedPrimary: {
					color: "#FFFFFF",
					boxShadow: "none",
					textTransform: "capitalize",
					position: "relative",
					"&.MuiButton-sizeMedium": {
						padding: "11px 16px",
					},
					"&:hover": {
						boxShadow: "none",
						backgroundColor: "#00a9d0",
					},
					"&:active": {
						transform: "scale(0.97)",
					},
					"&.Mui-disabled": {
						color: "#FFFFFF",
						backgroundColor: "rgba(0, 188, 231, 0.5)",
					},
					"@media(max-width:1199px)": {
						fontSize: 16,
						lineHeight: "26px",
					},
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.12)",
					"@media(max-width:599px)": {
						position: "fixed",
						top: "0",
						left: "0",
						right: "0",
						zIndex: "10",
					},
				},
			},
		},
		MuiMenu: {
			"&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
				boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.1)",
				borderRadius: "6px",
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					padding: "10px 0",
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					padding: "3px 16px",
					"&:hover:not(.active), &:focus:not(.active)": {
						backgroundColor: "rgba(0, 0, 0, 0.12)",
					},
					"@media(max-width:899px)": {
						minHeight: "32px",
					},
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					"& .MuiTableCell-root": {
						padding: "10px 11px 9px 14px",
						fontWeight: "500",
						lineHeight: "25.5px",
						color: [myCustomTheme.palette.text.primary],
					},
					"& .MuiTableCell-root:first-child": {
						paddingLeft: "20px",
					},
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					"& .MuiTableCell-root": {
						padding: "8px 11px 7px 14px",
						fontWeight: "400",
						color: "#FFFFFF",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						minHeight: "52px",
						height: "52px",
						verticalAlign: "middle",
					},
					"& .MuiTableCell-root:first-child": {
						paddingLeft: "20px",
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					lineHeight: "26px",
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					lineHeight: "20px",
					fontWeight: 500,
					"&.MuiButtonBase-root": {
						border: "1px solid",
						height: "36px",
						width: "36px",
						"@media(max-width:599px)": {
							height: "30px",
							width: "30px",
							minWidth: "28px",
						},
					},
					"&.Mui-selected": {
						backgroundColor: "#00BCE7",
						borderColor: "#00BCE7",
						color: "#FFFFFF",
						"&:hover": {
							backgroundColor: "#00BCE7",
						},
					},
					"&.Mui-disabled": {
						opacity: "0.4",
					},
					"@media(max-width:899px)": {
						marginLeft: "3px",
						marginRight: "3px",
					},
				},
			},
		},
	},
});

export default myCustomTheme;
