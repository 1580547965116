import { yupResolver } from "@hookform/resolvers/yup";
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	Hidden,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Tooltip,
	Typography,
} from "@mui/material";
import { LoginBanner, EyeOpen, EyeClose, Logo } from "../../assests/images";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import authService from "../../services/auth-service";
import { openSucessErrorModal, createCommonAPICall } from "../../utility/helpers";
import { newPasswordSchema } from "../../utility/validations";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import { IHttpsResponse, IResetPassWordForm } from "../../utility/interfaces";
import { LightDarkButton, AccountFooter } from "../../components";

const ResetPassword: React.FC = () => {
	// get query parameters
	const search = useLocation().search;
	const linkToVerify = new URLSearchParams(search).get("c");

	// useStates
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const [isLinkVerified, setIsLinkVerified] = useState(false);
	const [formValues, setFormValues] = useState<IResetPassWordForm>({
		confirmPassword: "",
		password: "",
	});

	// Page level local variables
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const navigate = useNavigate();

	// Handled events and functions
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword((show) => !show);
	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};
	const handleOnChangeFormValue = (e: any) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	};

	const handleOnClickOk = () => {
		navigate(AppRoutings.Root);
	};

	const handleOnClickReset = async () => {
		const data: IHttpsResponse<any> = await createCommonAPICall({
			requestBody: {
				password: formValues.password,
				confirmPassword: formValues.confirmPassword,
				linkToVerify,
			},
			apiService: authService.resetPassword,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) {
			openSucessErrorModal(
				setSuccessErrorContext,
				"Success",
				data.message,
				true,
				handleOnClickOk
			);
		}
	};
	const verifyUrlCode = async () => {
		const data: IHttpsResponse<any> = await createCommonAPICall({
			requestBody: linkToVerify,
			apiService: authService.resetPasswordVerifyLink,
			showSuccessMessage: false,
			showErrorMessage: true,
			setSuccessErrorContext,
		});
		if (data && data.isSuccessfull) setIsLinkVerified(true);
	};

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(newPasswordSchema),
	});

	// useEffects
	useEffect(() => {
		verifyUrlCode();
	}, []);

	return (
		<>
			<section className="login-wrapper">
				<Grid container className="login-container">
					{/* <Hidden mdDown>
						<Grid item xs={12} md={6} className="login-banner">
							<img src={LoginBanner} alt="Banner" />
						</Grid>
					</Hidden> */}
					<Grid item xs={12} className="login-content-container">
						<LightDarkButton />
						<div className="login-content">
							<Tooltip title="Doctors To You">
								<a href="/" className="logo">
									<img src={Logo} alt="Doctors To You" />
								</a>
							</Tooltip>
							<div className="login-content-form">
								<Typography variant="h1">Reset your password</Typography>
								<form onSubmit={handleSubmit(handleOnClickReset)}>
									<div
										className="login-form-control"
										style={{ display: "flex", flexDirection: "column" }}
									>
										<FormControl variant="outlined">
											<InputLabel htmlFor="password">Password</InputLabel>
											<OutlinedInput
												disabled={!isLinkVerified}
												{...register("password")}
												id="password"
												error={errors?.password?.message ? true : false}
												className="with-icon"
												name="password"
												onChange={handleOnChangeFormValue}
												autoFocus
												tabIndex={1}
												type={showPassword ? "text" : "password"}
												value={formValues.password}
												endAdornment={
													<Tooltip title={showPassword ? "Hide" : "Show"}>
														<InputAdornment position="end" tabIndex={-1}>
															<IconButton
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
																disableFocusRipple
																disableRipple
																tabIndex={-1}
															>
																{showPassword ? (
																	<img
																		tabIndex={-1}
																		src={EyeClose}
																		alt="show"
																	/>
																) : (
																	<img tabIndex={-1} src={EyeOpen} alt="hide" />
																)}
															</IconButton>
														</InputAdornment>
													</Tooltip>
												}
												label="Password"
											/>
											<FormHelperText error id="component-error-text">
												<>{errors?.password?.message}</>
											</FormHelperText>
										</FormControl>
										<FormControl variant="outlined">
											<InputLabel htmlFor="confirmPassword">
												Confirm Password
											</InputLabel>
											<OutlinedInput
												disabled={!isLinkVerified}
												{...register("confirmPassword")}
												id="confirmPassword"
												error={errors?.confirmPassword?.message ? true : false}
												type={showConfirmPassword ? "text" : "password"}
												name="confirmPassword"
												className="with-icon"
												tabIndex={2}
												value={formValues.confirmPassword}
												onChange={handleOnChangeFormValue}
												endAdornment={
													<Tooltip
														title={showConfirmPassword ? "Hide" : "Show"}
													>
														<InputAdornment position="end" tabIndex={-1}>
															<IconButton
																onClick={handleClickShowConfirmPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
																disableFocusRipple
																disableRipple
																tabIndex={-1}
															>
																{showConfirmPassword ? (
																	<img
																		tabIndex={-1}
																		src={EyeClose}
																		alt="show"
																	/>
																) : (
																	<img tabIndex={-1} src={EyeOpen} alt="hide" />
																)}
															</IconButton>
														</InputAdornment>
													</Tooltip>
												}
												label="Confirm Password"
											/>
											<FormHelperText error id="component-error-text">
												<>{errors?.confirmPassword?.message}</>
											</FormHelperText>
										</FormControl>
										<Tooltip title="Reset">
											<Button
												disabled={!isLinkVerified}
												variant="contained"
												type="submit"
											>
												Reset
											</Button>
										</Tooltip>
									</div>
								</form>
							</div>
							<AccountFooter />
						</div>
					</Grid>
				</Grid>
			</section>
		</>
	);
};

export default ResetPassword;
