import { useEffect, useState } from "react";
import { DarkIcon, LightIcon } from "../../assests/images";
import { Button, Tooltip } from "@mui/material";

export const LightDarkButton = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "light-theme"
  );
  const toggleTheme = () => {
    if (theme === "light-theme") {
      setTheme("dark");
    } else {
      setTheme("light-theme");
    }
  };
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.body.className = theme;
  }, [theme]);

  return (
    <Tooltip title={theme === "light-theme" ? "Dark" : "Light"}>
      <Button
        variant="outlined"
        className="toggle-button"
        onClick={toggleTheme}
      >
        <img src={DarkIcon} alt="moon" className="dark-mode" />
        <img src={LightIcon} alt="sun" className="light-mode" />
      </Button>
    </Tooltip>
  );
};

