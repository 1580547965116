import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { IInformationTab } from "../../utility/interfaces/common/info-tabs";
interface LinkTabProps {
  label?: any;
  href?: string;
  value?: number;
}

type ComponentProps = {
  tabs: IInformationTab[];
  currentTabValue: number;
  handleTabChange: any;
  repeatSequence: number;
};

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      disableFocusRipple
      disableTouchRipple
      disableRipple
      {...props}
    />
  );
}

export function InformationTab(props: ComponentProps) {
  // Extract Props
  const { tabs, currentTabValue, handleTabChange, repeatSequence } = props;

  return (
    <Tabs
      value={+currentTabValue}
      className={`main-tabs main-tabs-repeat-1`}
    >
      {tabs.map((tab) => {
        return currentTabValue === 0 || currentTabValue === tab.type ? (
          <LinkTab
            key={tab.type}
            label={
              <React.Fragment>
                <div
                  className={`tab-item ${tab.class}`}
                  onClick={() => handleTabChange(tab)}
                >
                  <Box style={{ height: "50px" }}>
                    <div className="tab-title">{tab.title}</div>
                  </Box>
                </div>
              </React.Fragment>
            }
            value={tab.type}
          />
        ) : (
          <></>
        );
      })}
    </Tabs>
  );
}
