import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LocationIcon } from "../../assests/images";
import { INewRequestDetails } from "../../utility/interfaces/create-request/create-request-details";
import { patientFormSchema } from "../../utility/validations/create-request";
import CustomDatePicker from "../common/custom-date-picker";
import { UploadDocument } from "../common/upload-document";
import dashboardService from "../../services/dashboard-service";
import {
  createCommonAPICall,
  hideLoader,
  openSucessErrorModal,
} from "../../utility/helpers";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import { CreateRequestType } from "../../utility/enums/create-request";
import createRequestService from "../../services/create-request-service";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import { IRegions } from "../../utility/interfaces";
import Geocode from "react-geocode";
import { PhoneTypeModal } from "../common/phonetype-modal";
import { UserContext } from "../../contexts/user-context";
import CustomIntlTelInput from "../common/custom-intl-tel-input";
import { Constants } from "utility/enums/constants";

type ComponentProps = {
  handleOnBackButtonClick: any;
  isRequestForMe: boolean;
};

const PatientDetails = (props: ComponentProps) => {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
  const contextUser = useContext(UserContext);
  Geocode.setApiKey(Constants.GeocodeKey);

  // page level variables
  const navigate = useNavigate();

  // useStates
  const [patientDetails, setPatientDetails] = useState<INewRequestDetails>({
    symptoms: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    phoneNumber: "",
    radioClientPhoneType: "M",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    room: "",
    document: "",
    documentName: "",
    relationWithPatients: "",
    clientRegionId: 0,
  });
  const [requestGuid, setRequestGuid] = useState("");
  const [fileName, setFileName] = useState("");
  const [stateResponse, setStateResponse] = useState<IRegions[]>([]);
  const [txtstate, setTxtState] = useState<string[]>([]);
  const [phoneTypeModelOpen, setphoneTypeModelOpen] = useState(false);
  const [requestorInfo, setRequestorInfo] = useState({
    requestorFirstname: "",
    requestorLastname: "",
    requestorEmail: "",
    requestorPhone: "",
  });
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [rerender, setRerender] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");

  // Yup resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: patientDetails,
    resolver: yupResolver(patientFormSchema),
  });

  // Extract Props
  const { handleOnBackButtonClick, isRequestForMe } = props;

  // Handled event(s)
  const handlePhoneTypeModelOpen = () => setphoneTypeModelOpen(true);

  const handlePhoneTypeModelClose = () => {
    setphoneTypeModelOpen(false);
  };

  const onRadioChange = (event: any) => {
    onChangePatientDetails("radioClientPhoneType", event);
    // handlePhoneTypeModelClose();
  };

  const onSelectNewFiles = async (event: any) => {
    const fileNames: string[] = [];
    const formData = new FormData();
    if (event.target.files && event.target.files.length > 0) {
      Array.prototype.forEach.call(event.target.files, (file: any) => {
        fileNames.push("C:\\fakepath\\" + file.name);
      });
      onChangePatientDetails("documentName", fileNames);
      for (var i = 0; i < event.target.files.length; i++) {
        formData.append("files", event.target.files[i]);
      }

      const data = await createCommonAPICall({
        requestBody: formData,
        apiService: createRequestService.uploadFileForRequest,
        showErrorMessage: true,
        showSuccessMessage: false,
        setSuccessErrorContext,
      });

      if (data && data.isSuccessfull) {
        console.log(data);
        if (data.data.uploadStatuses[0].status)
          setRequestGuid(data.data.requestGuid);
        if (data.data.fileName) setFileName(data.data.fileName);
      }
    }
  };
  const onChangePatientDetails = (name: string, value: any) => {
    setPatientDetails({ ...patientDetails, [name]: value });
  };

  const handleOnClickSubmit = async () => {
    console.log("Submit", patientDetails);
    var address =
      patientDetails.street +
      " " +
      patientDetails.city +
      ", " +
      patientDetails.state +
      " " +
      patientDetails.zipCode;

    if (
      new Date(patientDetails.birthDate!).toLocaleString('en-US') !== "Invalid Date"
    ) {
      Geocode.fromAddress(address).then(
        async (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng, response);
          var isValid = await CheckValidRegion(response.results[0]);
          if (isValid) {
            if (isRequestForMe) {
              const requestBody = {
                requestTypeId: CreateRequestType.patient,
                clientNotes: patientDetails.symptoms,
                firstName: patientDetails.firstName,
                lastName: patientDetails.lastName,
                dateOfBirth: new Date(patientDetails.birthDate),
                strDateOfBirth: new Date(
                  patientDetails.birthDate!
                ).toLocaleString('en-US'),
                phoneNumber: patientDetails.phoneNumber,
                email: patientDetails.email,
                street: patientDetails.street,
                city: patientDetails.city,
                state: patientDetails.state,
                zipCode: patientDetails.zipCode,
                room: patientDetails.room,
                radioClientPhoneType: patientDetails.radioClientPhoneType,
                radioRequestorPhoneType: patientDetails.radioClientPhoneType,
                clientRegionId: patientDetails.clientRegionId,
                requestGuid: requestGuid,
                fileName: fileName,
              };

              const data = await createCommonAPICall({
                requestBody,
                apiService: createRequestService.createRequest,
                showErrorMessage: true,
                showSuccessMessage: true,
                setSuccessErrorContext,
              });

              if (data && data.isSuccessfull) {
                navigate(AppRoutings.Dashboard);
              }
            } else {
              const requestBody = {
                requestorFirstName: requestorInfo.requestorFirstname,
                requestorLastName: requestorInfo.requestorLastname,
                requestorEmail: requestorInfo.requestorEmail,
                requestorPhoneNumber: requestorInfo.requestorPhone,
                requestTypeId: CreateRequestType.familyOrFriend,
                clientNotes: patientDetails.symptoms,
                firstName: patientDetails.firstName,
                lastName: patientDetails.lastName,
                dateOfBirth: new Date(patientDetails.birthDate),
                strDateOfBirth: new Date(
                  patientDetails.birthDate!
                ).toLocaleString('en-US'),
                phoneNumber: patientDetails.phoneNumber,
                email: patientDetails.email,
                street: patientDetails.street,
                city: patientDetails.city,
                state: patientDetails.state,
                zipCode: patientDetails.zipCode,
                room: patientDetails.room,
                radioClientPhoneType: patientDetails.radioClientPhoneType,
                radioRequestorPhoneType: "M",
                clientRegionId: patientDetails.clientRegionId,
                relationWithPatient: patientDetails.relationWithPatients,
                requestGuid: requestGuid,
                fileName: fileName,
              };

              const data = await createCommonAPICall({
                requestBody,
                apiService: createRequestService.createOtherTypeRequest,
                showErrorMessage: true,
                showSuccessMessage: true,
                setSuccessErrorContext,
              });

              if (data && data.isSuccessfull) {
                navigate(AppRoutings.Dashboard);
              }
            }
          } else {
            hideLoader();
            openSucessErrorModal(
              setSuccessErrorContext,
              "Error",
              "At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
              false
            );
          }
        },
        (error) => {
          openSucessErrorModal(
            setSuccessErrorContext,
            "Error",
            "At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
            false
          );
        }
      );
    } else {
      hideLoader();
      openSucessErrorModal(
        setSuccessErrorContext,
        "Error",
        "Please enter a valid birthdate (MM/DD/YYYY)",
        false
      );
    }
  };

  const CheckValidRegion = async (places: any) => {
    // get state from address
    var isValidState = false;
    var stateName;
    for (var component in places["address_components"]) {
      for (var i in places["address_components"][component]["types"]) {
        if (
          places["address_components"][component]["types"][i] ==
          "administrative_area_level_1"
        ) {
          stateName = places["address_components"][component]["long_name"];
          for (var j = 0; j < txtstate.length; j++) {
            if (stateName == txtstate[j]) {
              isValidState = true;
              for (let s = 0; s < stateResponse.length; s++) {
                if (stateResponse[s].name == stateName) {
                  patientDetails.clientRegionId = stateResponse[s].regionId;
                }
              }
            }
          }
        }
      }
    }
    if (isValidState && isValidState == true) {
      const zipCodesRes = await createCommonAPICall({
        apiService: dashboardService.getAllZipCodes,
        showErrorMessage: true,
        showSuccessMessage: false,
        setSuccessErrorContext,
      });

      if (zipCodesRes && zipCodesRes.isSuccessfull) {
        console.log(zipCodesRes);
        if (
          !zipCodesRes.data.some(
            (zip: any) => zip.zipCode == patientDetails.zipCode
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else {
      console.log(
        "At this time, we" +
          "'" +
          "re only able to service select areas in DC, Maryland, Virginia, and New York City."
      );
    }
  };

  const getNewRequestInfoForMe = async () => {
    // API call here
    const data = await createCommonAPICall({
      apiService: dashboardService.getCreateRequestInfoForMe,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      console.log(data.data.profile);
      const profile = data.data.profile;
      if (isRequestForMe) {
        setPatientDetails({
          ...patientDetails,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          phoneNumber: profile.phoneNumber,
        });
      } else {
        setRequestorInfo({
          requestorFirstname: profile.firstName,
          requestorLastname: profile.lastName,
          requestorEmail: profile.email,
          requestorPhone: profile.phoneNumber,
        });
      }

      for (let i = 0; i < data.data.regions.length; i++) {
        txtstate.push(data.data.regions[i].name);
      }
      setStateResponse(data.data.regions);
      setRerender(1);
    }
  };

  const handlePhoneNumberChange = (
    isValid: any,
    phoneNumber: any,
    countryData: any
  ) => {
    setIsPhoneNumberValid(isValid);
    let formattedPhoneNumber =
      phoneNumber != ""
        ? phoneNumber.includes("+")
          ? phoneNumber
          : `+${countryData.dialCode} ${phoneNumber}`
        : "";
    onChangePatientDetails("phoneNumber", formattedPhoneNumber);
  };

  // use effects
  useEffect(() => {
    getNewRequestInfoForMe();
  }, []);

  return (
    <>
      <Typography variant="h4">Patient Information</Typography>
      <div>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={12}>
            <TextField
              {...register("symptoms")}
              name="symptoms"
              id="symptoms"
              label="Enter Brief Details Of Symptoms"
              value={patientDetails.symptoms}
              multiline
              maxRows={1}
              inputProps={{
                style: { minHeight: "88px" },
              }}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
              error={errors.symptoms?.message ? true : false}
              helperText={errors.symptoms?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("firstName")}
              name="firstName"
              id="firstName"
              label="First Name"
              value={patientDetails.firstName}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
              error={errors?.firstName?.message ? true : false}
              helperText={errors?.firstName?.message?.toString()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("lastName")}
              error={errors?.lastName?.message ? true : false}
              helperText={errors?.lastName?.message?.toString()}
              name="lastName"
              id="lastName"
              label="Last Name"
              value={patientDetails.lastName}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              disabled={false}
              label="Date Of Birth"
              name="birthDate"
              value={patientDetails.birthDate}
              onChange={onChangePatientDetails}
              // register={register}
              error={errors?.birthDate?.message ? true : false}
              message={errors?.birthDate?.message}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h4">Patient Contact Information</Typography>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("email")}
              error={errors?.email?.message ? true : false}
              helperText={errors?.email?.message?.toString()}
              name="email"
              id="email"
              label="Email"
              value={patientDetails.email}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {rerender > 0 && (
              <CustomIntlTelInput
                isPhoneNumberValid={isPhoneNumberValid}
                onChange={handlePhoneNumberChange}
                onBlur={() => {
                  if (patientDetails.phoneNumber !== "")
                    handlePhoneTypeModelOpen();
                }}
                value={patientDetails.phoneNumber}
                name="phoneNumber"
              />
            )}

            {/* <TextField
              {...register("phoneNumber")}
              error={errors?.phoneNumber?.message ? true : false}
              helperText={errors?.phoneNumber?.message?.toString()}
              name="phoneNumber"
              id="phoneNumber"
              label="PhoneNumber"
              value={patientDetails.phoneNumber}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
              onBlurCapture={() => {
                if (patientDetails.phoneNumber !== "")
                  handlePhoneTypeModelOpen();
              }}
            /> */}
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant="h4">Patient Location</Typography>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("street")}
              error={errors?.street?.message ? true : false}
              helperText={errors?.street?.message?.toString()}
              name="street"
              id="street"
              label="Street"
              value={patientDetails.street}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("city")}
              error={errors?.city?.message ? true : false}
              helperText={errors?.city?.message?.toString()}
              name="city"
              id="city"
              label="City"
              value={patientDetails.city}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("state")}
              error={errors?.state?.message ? true : false}
              helperText={errors?.state?.message?.toString()}
              name="state"
              id="state"
              label="State"
              value={patientDetails.state}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("zipCode")}
              error={errors?.zipCode?.message ? true : false}
              helperText={errors?.zipCode?.message?.toString()}
              name="zipCode"
              id="zipCode"
              label="Zip Code"
              value={patientDetails.zipCode}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="room"
              id="room"
              label="Room # / Suite (Optional)"
              value={patientDetails.room}
              fullWidth
              onChange={(e) =>
                onChangePatientDetails(e.target.name, e.target.value)
              }
            />
          </Grid>
        </Grid>
      </div>
      {!isRequestForMe && (
        <div>
          <Typography variant="h4">Relation</Typography>
          <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="relationWithPatients"
                id="relationWithPatients"
                label="Relation With Patient (Optional)"
                value={patientDetails.relationWithPatients}
                fullWidth
                onChange={(e) =>
                  onChangePatientDetails(e.target.name, e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      )}
      <div>
        <Typography variant="h4">
          Upload Photo or Document (Optional)
        </Typography>
        <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
          <Grid item xs={12} sm={12}>
            <UploadDocument
              accept="*"
              fileName={patientDetails.documentName}
              isMultiple={false}
              onSelectNewFiles={onSelectNewFiles}
            />
          </Grid>
        </Grid>
      </div>
      <div className="request-btn-group">
        <Tooltip title="Click Here To Submit">
          <Button
            onClick={() => {
              if (patientDetails.phoneNumber == "" || !isPhoneNumberValid) {
                setIsPhoneNumberValid(false);
                handleSubmit(() => {})();
              } else {
                handleSubmit(handleOnClickSubmit)();
              }
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Tooltip>
        <Tooltip title="Click Here To Cancel">
          <Button onClick={handleOnBackButtonClick} variant="outlined">
            Cancel
          </Button>
        </Tooltip>
      </div>

      {/* Page modals */}
      <PhoneTypeModal
        phoneTypeModelOpen={phoneTypeModelOpen}
        handlePhoneTypeModelClose={handlePhoneTypeModelClose}
        onSelectPhoneType={onRadioChange}
      ></PhoneTypeModal>
    </>
  );
};

export default PatientDetails;
