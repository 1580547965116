export enum ProtectedEndPoints {
  GetUserDashboardGrid = "care/user-dashboard-grid",
  GetViewUploadsInfo = "viewUploads/get-view-uploads",
  UploadFiles = "viewUploads/upload-files",
  DownloadFiles = "viewUploads/downloadfiles",
  DownloadMultipleFiles = "viewUploads/download-mulitple-files",
  GetCreateCareProfile = "care/get-care-profile",
  GetCreateRequestInfoForMe = "care/get-create-request-info",
  UpdateCareProfile = "care/update-care-profile",
  uploadAudioVideoForChat = "chat/uploadAudioVideoForChat",
  GetLoggedInUserId = "general/get-logged-in-userid",
  GetCurrentProviderLocation = "care/get-current-provider-location",
}

export enum UnProtectedEndPoints {
  GenerateDefaultToken = "authorize/generate-default-token",
  PatientLogin = "authorize/login",
  ForgotPassword = "authorize/forgot-password",
  ResetPasswordVerifyLink = "authorize/reset-password-verify-link",
  ResetPassword = "authorize/reset-password",
  CreateAccount = "authorize/create-account",
  CheckRegisteredUser = "authorize/check-registered-user",
  GetRegions = "request/get-regions",
  CreateRequest = "request/create-patient-request",
  UploadFileForRequest = "request/upload-file-for-request",
  CreateOtherTypeRequest = "request/create-other-type-request",
  CheckAgreementLinkValidity = "request/check-agreement-link",
  AcceptOrRejectAgreement = "request/accept-or-reject-agreement",
  GetAllZipCodes = "general/get-all-zipcodes",
}
