import { Constants } from "utility/enums/constants";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useContext, useEffect, useRef, useState } from "react";
import { ITrackProviderInfo } from "utility/interfaces";
import { openSucessErrorModal } from "utility/helpers";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";

type ComponentProps = {
  trackProviderInfo: ITrackProviderInfo;
};

export const GoogleMap = (props: ComponentProps) => {
  // Extract props
  const { trackProviderInfo } = props;

  // Context variables
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // Ref variables
  const ref = useRef<HTMLDivElement>(null);

  // State variables
  const [mapCenterLat, setMapCenterLat] = useState(38.9072); // Defualt latitude for Washington DC
  const [mapCenterLong, setMapCenterLong] = useState(-77.0369); // Defualt longitude for Washington DC

  // Local variables
  var directionsDisplay: google.maps.DirectionsRenderer;
  var directionService: google.maps.DirectionsService;
  var map: google.maps.Map;

  // Handle events
  const calculateRoute = (
    sLat: number,
    sLong: number,
    dLat: number,
    dLong: number
  ) => {
    console.log(sLat, sLong, dLat, dLong);
    var start = new google.maps.LatLng(sLat, sLong);
    var end = new google.maps.LatLng(dLat, dLong);
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(start);
    bounds.extend(end);
    map.fitBounds(bounds);

    var request: google.maps.DirectionsRequest = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionService.route(request, function (response, status) {
      console.log(response, status);
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
      } else {
        openSucessErrorModal(
          setSuccessErrorContext,
          "Error",
          `Direction request from ${start.toUrlValue(6)} to ${end.toUrlValue(
            6
          )} failed : ${status}`,
          false
        );
      }
    });
  };

  const handleRenderMap = () => {
    if (ref.current) {
      directionService = new google.maps.DirectionsService();
      directionsDisplay = new google.maps.DirectionsRenderer();
      var mapCenter = new google.maps.LatLng(mapCenterLat, mapCenterLong);
      var mapOptions = {
        zoom: 15,
        center: mapCenter,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      map = new window.google.maps.Map(ref.current, mapOptions);
      directionsDisplay.setMap(map);
      calculateRoute(
        trackProviderInfo.latitude,
        trackProviderInfo.longitude,
        trackProviderInfo.patientLat,
        trackProviderInfo.patientLong
      );
    } else {
      setTimeout(handleRenderMap, 500);
    }
  };

  // Use effects
  useEffect(() => {
    handleRenderMap();
  }, [trackProviderInfo]);

  return (
    <Wrapper apiKey={Constants.GoogleKey.valueOf()}>
      <div ref={ref} className="map-view"></div>
    </Wrapper>
  );
};
