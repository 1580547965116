import React from "react";
import { AppBar, Box, IconButton, Toolbar, Tooltip } from "@mui/material";
import { LightDarkButton } from "./light-dark-button";
import { Logo, MobileLogo } from "../../assests/images";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
const HeaderType1: React.FC = () => {
  //page level variable
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="static" className="header">
        <Toolbar disableGutters sx={{ flexWrap: "wrap" }}>
          <Box className="header-container">
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <Tooltip title="Doctors To You">
                <a className="header-logo">
                  <img
                    src={Logo}
                    alt="Doctors To You"
                    className="desktop"
                    onClick={() => navigate(AppRoutings.Root)}
                  />
                  <img
                    src={MobileLogo}
                    alt="Doctors To You"
                    className="mobile"
                    onClick={() => navigate(AppRoutings.Root)}
                  />
                </a>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 0 }} className="header-content">
              <LightDarkButton />
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              minWidth: "100%",
            }}
            className="menu-container"
          ></Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderType1;
