import { toast } from "react-toastify";
import { JpgIcon, PdfIcon, DocIcon } from "../../assests/images";
import { FileMimeTypes } from "../enums/file-mime-type";
import { IChatBoxInfo } from "../interfaces/common/chat-box-info";

const loaderDiv =
	typeof window !== "undefined" && document.getElementById("loader");

export const showLoader = () => {
	if (loaderDiv) {
		loaderDiv.style.opacity = "1";
		loaderDiv.style.visibility = "visible";
	}
};

export const hideLoader = () => {
	if (loaderDiv) {
		loaderDiv.style.opacity = "0";
		loaderDiv.style.visibility = "hidden";
	}
};

export const openSucessErrorModal = (
	setSuccessErrorContext: any,
	title: string,
	message: string,
	isSuccess: boolean,
	handleOnClickOk: any = undefined
) => {
	setSuccessErrorContext({
		isSuccessErrorOpen: true,
		title,
		message,
		isSuccess,
		handleOnClickOk,
	});
};

export const copyTextOnClipBoard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success("Text Copied");
};

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: any,
  orderBy: Key
): ((a: { [key in Key]: string }, b: { [key in Key]: string }) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getFileIconByType = (type: string) => {
  switch (type) {
    case FileMimeTypes.image:
      return JpgIcon;
    case FileMimeTypes.pdf:
      return PdfIcon;
    default:
      return DocIcon;
  }
};

export const commonModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
};
