
import { ICreateCommonAPIArgs } from "../interfaces";
import { hideLoader, openSucessErrorModal, showLoader } from "./common";
import { HttpStatusCodes } from "../enums/http-status-codes";

export const createCommonAPICall = async (args: ICreateCommonAPIArgs) => {
	const {
		requestBody,
		apiService,
		showSuccessMessage,
		showErrorMessage,
		setSuccessErrorContext,
		successMessage,
		errorMessage,
		isHideLoader = true,
	} = args;
	try {
		showLoader();
		const response = requestBody
			? await apiService(requestBody)
			: await apiService();
		if (response && response.status !== HttpStatusCodes.Unauthorized) {
			const { data } = response;
			if (data && data.isSuccessfull) {
				if (showSuccessMessage) {
					openSucessErrorModal(
						setSuccessErrorContext,
						"Success",
						successMessage ||
							data.message ||
							"Your request has been successfully executed",
						true
					);
				}
				return data;
			} else if (showErrorMessage && data && data.message) {
				hideLoader();
				openSucessErrorModal(
					setSuccessErrorContext,
					"Error",
					errorMessage || data.message,
					false
				);
			}
		}
	} catch (ex: any) {
		hideLoader();
		openSucessErrorModal(
			setSuccessErrorContext,
			"Error",
			errorMessage ||
				ex?.response?.data?.title ||
				"Something went wrong, please try again!",
			false
		);
	} finally {
		isHideLoader && hideLoader();
	}
};
