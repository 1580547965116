import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ViewDocuments } from "../../components/common/view-documents";
import { IDocumentInfo } from "../../utility/interfaces/common/document-info";
import { createCommonAPICall } from "../../utility/helpers";
import dashboardService from "../../services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";

const ViewUploadedDocuments: React.FC = () => {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // get query parameters
  const { requestId } = useParams();

  // get state value
  const { state } = window.history;

  // useStates
  // TODO: Dynamic
  const [patientName, setPatientName] = useState("");
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [documentList, setDocumentList] = useState<IDocumentInfo[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  // Handled events and functions
  const handleOnClickBackCancel = () => navigate(-1);

  // Handled event(s)
  const onSelectNewFiles = (event: any) => {
    console.log(event);
    console.log(event.target);
    console.log(event.target.files);
    const fileNames: string[] = [];
    const formdata = new FormData();
    if (event.target.files && event.target.files.length > 0) {
      Array.prototype.forEach.call(event.target.files, (file: File) => {
        fileNames.push("C:\\fakepath\\" + file.name);
        formdata.append("files", file);
      });
      setSelectedFiles(fileNames);
      // for (var i = 0; i < event.target.files.length; i++) {
      //   formdata.append("files", event.target.files[i]);
      // }
    }
    console.log(fileNames);

    formdata.append("RequestId", requestId ? requestId : "");
    formdata.append("docType", "12");

    createCommonAPICall({
      requestBody: formdata,
      apiService: dashboardService.uploadFiles,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    }).then((data) => {
      if (data && data.isSuccessfull) {
        getUploadDetails();
      }
    });
  };
  const getUploadDetails = async () => {
    // TODO API call here
    console.log(requestId);
    setSelectedFiles([]);
    const requestBody = {
      requestId: requestId ? requestId : "",
    };
    const data = await createCommonAPICall({
      requestBody,
      apiService: dashboardService.getViewUploadsInfo,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      console.log(data);
      setPatientName(
        data.data.clientFirstName + " " + data.data.clientLastName
      );
      setConfirmationNumber(data.data.confirmationNumber);
      if (data.data.fileNames != null && data.data.fileNames.length > 0)
        setDocumentList(data.data.fileNames);
      else setDocumentList([]);
    }
  };

  // Page level variables
  const navigate = useNavigate();

  // useEffects
  useEffect(() => {
    if (requestId) getUploadDetails();
  }, []);

  return (
    <>
      <ViewDocuments
        requestId={requestId || ""}
        selectedFiles={selectedFiles}
        onSelectNewFiles={onSelectNewFiles}
        documentList={documentList}
        handleOnClickBackCancel={handleOnClickBackCancel}
        patientName={patientName}
        confirmationNumber={confirmationNumber}
      />
    </>
  );
};

export default ViewUploadedDocuments;
