export const LoginBanner = require("./login-banner.png");
export const Logo = require("./logo.svg").default;
export const EyeClose = require("./visibility_off.svg").default;
export const EyeOpen = require("./visibility_on.svg").default;
export const AccountIcon = require("./account_circle.svg").default;
export const ArrowBack = require("./arrow-back-blue-ic.svg").default;
export const ArrowBackWhite = require("./arrow-back-white-ic.svg").default;
export const PasswordReset = require("./password-reset.svg").default;
export const DarkIcon = require("./dark-ic.svg").default;
export const LightIcon = require("./light-mode-ic.svg").default;
export const NewIconDefault = require("./new-default-ic.svg").default;
export const NewIconActive = require("./new-active-ic.svg").default;
export const WorkingIconDefault = require("./working-default-ic.svg").default;
export const WorkingIconActive = require("./working-active-ic.svg").default;
export const CheckedIconDefault = require("./checked-default-ic.svg").default;
export const CheckedIconActive = require("./checked-active-ic.svg").default;
export const ConcludeIconDefault = require("./conclude-default-ic.svg").default;
export const ConcludeIconActive = require("./conclude-active-ic.svg").default;
export const TocloseIconDefault = require("./to-close-default-ic.svg").default;
export const TocloseIconActive = require("./to-close-active-ic.svg").default;
export const UnpaidIconDefault = require("./ic-unpaid-default.svg").default;
export const UnpaidIconActive = require("./ic-unpaid-active.svg").default;
export const SendIcon = require("./send-ic.svg").default;
export const DocRequestIcon = require("./doc-request-ic.svg").default;
export const ExportIcon = require("./export-ic.svg").default;
export const ExportallIcon = require("./exportall-ic.svg").default;
export const CloseWhiteIcon = require("./close-white-ic.svg").default;
export const SearchIcon = require("./search-ic.svg").default;
export const DoctorDarkIcon = require("./doctor-dark-ic.svg").default;
export const DoctorLightIcon = require("./doctor-light-ic.svg").default;
export const DoctorIconDark = require("./ic-doctor-dark-mode.svg").default;
export const PatientDarkIcon = require("./patient-dark-ic.svg").default;
export const PatientLightIcon2 = require("./patient-light-ic.svg").default;
export const PatientLightIcon = require("./ic-patient.svg").default;
export const PatientIconDark = require("./ic-patient-dark-mode.svg").default;
export const TransferIcon = require("./transfer-ic.svg").default;
export const TransferDarkIcon = require("./ic-transfer-dark.svg").default;
export const AdministratorIcon = require("./administrator-ic.svg").default;
export const AdministratorDarkIcon =
  require("./ic-administrator-dark.svg").default;
export const MailIcon = require("./mail-ic.svg").default;
export const VerticalMenu = require("./vertical-menu-ic.svg").default;
export const CallIcon = require("./phone-ic.svg").default;
export const CallIconPrimary = require("./ic-call-primary.svg").default;
export const BlockIcon = require("./ic-block.svg").default;
export const BlockDarkIcon = require("./ic-block-dark.svg").default;
export const TasksIcon = require("./tasks-ic.svg").default;
export const TasksDarkIcon = require("./ic-tasks-dark.svg").default;
export const AnalysisIcon = require("./analysis-ic.svg").default;
export const AnalysisDarkIcon = require("./ic-analysis-dark.svg").default;
export const NotesIcon = require("./notes-ic.svg").default;
export const NotesDarkIcon = require("./ic-notes-dark.svg").default;
export const ErrorDarkIcon = require("./error-dark-ic.svg").default;
export const ErrorDarkModeIcon = require("./ic-error-dark-mode.svg").default;
export const MenuIcon = require("./menu-ic.svg").default;
export const LogoutIcon = require("./logout-ic.svg").default;
export const ClockIcon = require("./ic-clock.svg").default;
export const StatusCheckIcon = require("./ic-status-check.svg").default;
export const CalendarPrimaryIcon = require("./ic-calendar.svg").default;
export const CalendarDarkIcon = require("./ic-calendar-dark.svg").default;
export const CalendarIconDark = require("./ic-calendar-dark-mode.svg").default;
export const SmartphoneIcon = require("./ic-smartphone.svg").default;
export const SmartphoneDarkIcon = require("./ic-smartphone-dark.svg").default;
export const LocationIcon = require("./ic-location.svg").default;
export const ArrowDownIcon = require("./ic-arrow-downward.svg").default;
export const ChipDeleteIcon = require("./ic-chip-delete.svg").default;
export const ChipDeleteIconDark = require("./ic-chip-delete-dark.svg").default;
export const VerticalMenuIconDark =
  require("./ic-vertical-menu-dark.svg").default;
export const VerticalMenuIcon = require("./ic-vertical-menu.svg").default;
export const MailIconBlue = require("./ic-mail-blue.svg").default;
export const MoodIconDark = require("./ic-mood-dark.svg").default;
export const MoodIcon = require("./ic-mood.svg").default;
export const LinkIcon = require("./ic-link.svg").default;
export const DollarIcon = require("./ic-dollar.svg").default;
export const MinimizeIcon = require("./ic-minimize.svg").default;
export const MaximizeIcon = require("./ic-maximize.svg").default;
export const FooterLogo = require("./footer-logo.png");
export const FooterLogoMobile = require("./footer-logo-mobile.png");
export const MobileLogo = require("./mobile-logo.png");
export const Profile = require("./profile.png");
export const ArrowDownIconDark =
  require("./ic-arrow-downward-dark.svg").default;
export const OrdersIcon = require("./ic-orders.svg").default;
export const OrdersIconDark = require("./ic-orders-dark.svg").default;
export const UploadfileIcon = require("./ic-upload-file.svg").default;
export const UploadfileIconDark = require("./ic-upload-file-dark.svg").default;
export const DocnotesIcon = require("./ic-doc-notes.svg").default;
export const DocnotesIconDark = require("./ic-doc-notes-dark.svg").default;
export const HeartbeatIcon = require("./ic-heart-beat.svg").default;
export const HeartbeatIconDark = require("./ic-heart-beat-dark.svg").default;
export const UploadcloudIcon = require("./ic-upload-cloud.svg").default;
export const UploadcloudBlueIcon =
  require("./ic-upload-cloud-blue.svg").default;
export const PdfIcon = require("./ic-pdf-file.svg").default;
export const JpgIcon = require("./ic-jpg-file.svg").default;
export const DocIcon = require("./ic-doc-file.svg").default;
export const CheckIcon = require("./ic-check.svg").default;
export const UncheckIcon = require("./ic-uncheck.svg").default;
export const DownloadIcon = require("./ic-download.svg").default;
export const DeleteIcon = require("./ic-delete.svg").default;
export const RadioCheckIcon = require("./ic-radio-checked.svg").default;
export const RadioUncheckIcon = require("./ic-radio-uncheck.svg").default;
export const CopyIcon = require("./ic-copy.svg").default;
export const DeleteAllIcon = require("./ic-delete-all.svg").default;
export const DownloadAllIcon = require("./ic-download-all.svg").default;
export const ExclamationIcon = require("./ic-exclamation.svg").default;
export const RadioUncheckIconDark =
  require("./ic-radio-uncheck-dark.svg").default;
export const CrossRedIcon = require("./cross-red-icon.svg").default;
export const RequestorIcon = require("./Requestor.svg").default;
export const PhysicianIcon = require("./Physician.svg").default;
export const PhysicianPrimaryIcon = require("./Physician-primary.svg").default;

export const ClearCaseIcon = require("./ClearCase.svg").default;
export const SendAgreementIcon = require("./SendAgreement.svg").default;
export const ClearCaseDarkIcon = require("./ClearCaseDark.svg").default;
export const RegionIcon = require("./Region.svg").default;
export const SendAgreementDarkIcon = require("./SendAgreementDark.svg").default;
export const WarningIcon = require("./ic-warning.svg").default;
export const AddIcon = require("./ic-add.svg").default;
export const CopyDarkIcon = require("./ic-copy-dark.svg").default;
export const PatientPrimaryIcon = require("./ic-patient-primary.svg").default;
export const DoctorPrimaryIcon = require("./ic-doctor-primary.svg").default;
export const CalendarIcon = require("./ic-calendar-primary.svg").default;
export const StatusCheckPrimaryIcon =
  require("./ic-status-check-primary.svg").default;
export const ClockPrimaryIcon = require("./ic-clock-primary.svg").default;
export const MailPrimaryIcon = require("./ic-mail-primary.svg").default;
export const PhonePrimaryIcon = require("./ic-phone-primary.svg").default;
export const UnassignedIcon = require("./ic-unassigned.svg").default;
export const UnassignedDefaultIcon =
  require("./ic-unassigned-default.svg").default;

export const FollowupDefaultIcon = require("./ic-follow-up.svg").default;
export const FollowupIcon = require("./ic-follow-up-default.svg").default;
export const User = require("./user.jpg");
