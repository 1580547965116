import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_API_URL, APPLICATION, APPLICATION_TYPE } from "../config";
import { AppRoutings } from "../utility/enums/app-routings";
import { HttpStatusCodes } from "../utility/enums/http-status-codes";
import { ProtectedEndPoints, UnProtectedEndPoints } from "./api-end-points";
import tokenManager from "../utility/auth-guards/token-manager";

axios.interceptors.request.use(
	async (config: AxiosRequestConfig | any) => {
		config.headers["Access-Control-Allow-Origin"] = "*";
		const protectedUrl = Object.values(ProtectedEndPoints).find(
			(url) => url === config.url
		);
		const isProtectedUrl = protectedUrl ? true : false;
		if (isProtectedUrl) {
			config.headers.Authorization = tokenManager.getToken();
			config.headers.DtySecret = tokenManager.getDtySecret();
		} else {
			if (config.url !== UnProtectedEndPoints.GenerateDefaultToken) {
				config.headers.DtySecret = await tokenManager.getNewDtySecret();
			}
		}
		if (config.url) {
			config.url = BASE_API_URL + config.url;
		}
		config.headers.Application = APPLICATION;
		config.headers.ApplicationType = APPLICATION_TYPE;
		const useDetails = tokenManager.getUserDetails();
		if (useDetails && useDetails.encUserName)
			config.headers.UserName = useDetails?.encUserName;
		config.headers.IpAddress = await tokenManager.getDeviceIpAddress();
		return config;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (error: AxiosError) => {
		console.log("API call error : ", error);
		switch (error.response?.status) {
			case HttpStatusCodes.Unauthorized: {
				tokenManager.clearSession(window.location.pathname);
				return undefined;
			}
			case HttpStatusCodes.Forbidden: {
				if (window) window.location.replace(AppRoutings.AccessDenied);
				return undefined;
			}
			case HttpStatusCodes.BadRequest:
			case HttpStatusCodes.ConflictError:
			case HttpStatusCodes.ServerError:
				return Promise.reject(error);
			default:
				return Promise.reject(error);
		}
	}
);

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
	patch: axios.patch,
	CancelToken: axios.CancelToken,
};
