import { ArrowBack } from "assests/images";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/user-context";
import { AccountType, Constants } from "utility/enums/constants";
import {
  IChatBoxInfo,
  IChatListView,
  IGroupChatBoxInfo,
} from "utility/interfaces";
import { ChatList, ChatBox, GroupChatBox } from "components";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { createCommonAPICall, hideLoader, showLoader } from "utility/helpers";
// import profileService from "services/profile-service";
import { toast } from "react-toastify";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import {
  ChatCountContext,
  ChatCountDispatchContext,
} from "contexts/chat-count-context";

const URL = Constants.HubUrl;

const Chats: React.FC = () => {
  // Use context
  const contextUser = useContext(UserContext);
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
  const chatCount = useContext(ChatCountContext);
  const setChatCount = useContext(ChatCountDispatchContext);

  //Page level local variable
  const navigate = useNavigate();

  // Use state
  const [chatList, setChatList] = useState<IChatListView[]>([]);
  const [recieverType, setRecieverType] = useState(AccountType.Physician);
  const [connection, setConnection] = useState<null | signalR.HubConnection>(
    null
  );
  const [senderId, setSenderId] = useState(0);
  const [openChatBoxList, setOpenChatBoxList] = useState<IChatBoxInfo[]>([]);
  const [isChatOpend, setIsChatOpend] = useState(false);

  const [openGroupChatBoxList, setOpenGroupChatBoxList] = useState<
    IGroupChatBoxInfo[]
  >([]);

  // Handle Event and Functions
  const handleOnClickBackCancel = () => navigate(-1);
  const getChatList = () => {
    showLoader();
    if (connection) {
      connection!.send(
        "GetChatList",
        0,
        contextUser.userRoleId,
        recieverType,
        senderId,
        null,
        null
      );
    }
  };

  const handleOpenChatClick = async (index: number) => {
    // let chatView = chatList.at(index);
    let chatView = chatList.find((v, i) => i === index);
    if (!chatView) return;
    setChatCount(chatCount - chatView.readCount);
    if (recieverType !== AccountType.Groups) {
      let recieverId = 0;
      if (chatView.adminId != null && chatView.physicianId == null)
        recieverId = chatView.adminId;
      else if (chatView.physicianId != null && chatView.adminId == null)
        recieverId = chatView.physicianId;
      else return;

      let senderName = `${contextUser.lastName} ${contextUser.firstName}`;
      const request = {
        senderId,
        userRoleId: contextUser.userRoleId,
        senderName,
        recieverId,
        recieverType,
        requestId: chatView.requestId || 0,
        confirmationNumber: chatView.confirmationNumber || "",
        userId: chatView.userId,
      };
      if (connection) {
        showLoader();
        connection.send(
          "hubconnect",
          chatView.requestId,
          AccountType.Patient,
          senderName,
          recieverId,
          recieverType,
          chatView.requestId || 0,
          chatView.confirmationNumber || "",
          chatView.userId || 0
        );
      }
      console.log(request);
    } else {
      console.log(chatView);
      const request = {
        adminId: chatView.adminId,
        physicianId: chatView.physicianId,
        requestId: chatView.requestId,
        senderTypeId: contextUser.userRoleId,
      };
      console.log("OpenGroupChat: ", request);
      if (connection) {
        showLoader();
        await connection.send(
          "OpenGroupChat",
          chatView.adminId,
          chatView.physicianId,
          chatView.requestId,
          contextUser.userRoleId
        );
      }
    }
  };

  const handleOnCloseChat = async (
    receiverId: number,
    senderId: number,
    senderTypeId: number,
    receiverTypeId: number
  ) => {
    const openChatBoxes = openChatBoxList.filter(
      (box) => box.receiverId !== receiverId || box.senderId !== senderId
    );
    setIsChatOpend(false);
    setOpenChatBoxList([...openChatBoxes]);
    await connection!.send(
      "GetDisconnect",
      senderId,
      senderTypeId,
      receiverId,
      receiverTypeId
    );
  };

  const handleOnCloseGroupChat = async (chatBoxIndex: number) => {
    const openChatBoxes = openGroupChatBoxList.filter(
      (box, index) => index !== chatBoxIndex
    );
    setOpenGroupChatBoxList([...openChatBoxes]);

    await connection!.send(
      "GetDisconnect",
      senderId,
      contextUser.userRoleId,
      0,
      0
    );
  };

  const handleOnMinimized = (receiverId: number, senderId: number) => {
    const chatBoxes = openChatBoxList.map((box) => {
      if (box.receiverId === receiverId && box.senderId === senderId) {
        box.isMinimized = !box.isMinimized;
      }
      return box;
    });
    setOpenChatBoxList(chatBoxes);
  };

  const handleOnGroupChatMinimized = (chatBoxIndex: number) => {
    const chatBoxes = openGroupChatBoxList.map((box, index) => {
      if (index === chatBoxIndex) {
        box.isMinimized = !box.isMinimized;
      }
      return box;
    });
    setOpenGroupChatBoxList(chatBoxes);
  };

  const sendMessage = async (
    message: string,
    senderId: number,
    receiverId: number,
    receiverAccountType: AccountType,
    confirmationNumber: string,
    senderAccountType: AccountType
  ) => {
    console.log("sendMessage", receiverAccountType);
    await connection!.send(
      "SendPrivateMessage",
      message,
      senderId,
      receiverId,
      receiverAccountType,
      confirmationNumber,
      senderAccountType,
      "",
      0
    );
  };

  const handleSendGroupMessage = async (
    chatGroupId: number,
    message: string,
    senderId: number,
    senderTypeId: number
  ) => {
    if (connection) {
      console.log(
        "Send group message: ",
        chatGroupId,
        message,
        senderId,
        senderTypeId
      );
      connection.send(
        "SendGroupMessage",
        chatGroupId,
        message,
        senderId,
        senderTypeId,
        "",
        0
      );
    }
  };

  const sendAudioVideo = (
    file: Blob,
    senderId: number,
    receiverId: number,
    receiverAccountType: AccountType,
    confirmationNumber: string,
    senderAccountType: AccountType
  ) => {
    console.log("File Sent");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result && typeof reader.result == "string") {
        const chatUploadMedia = {
          File: reader.result,
          SenderId: senderId,
          ReceiverId: receiverId,
          ReceiverAccountTypeId: receiverAccountType,
          ConfirmationNumber: confirmationNumber,
          SenderAccountTypeId: senderAccountType,
        };
        console.log(chatUploadMedia);
        createCommonAPICall({
          requestBody: chatUploadMedia,
          apiService: dashboardService.uploadAudioVideoForChat,
          showSuccessMessage: false,
          showErrorMessage: false,
          setSuccessErrorContext,
        }).then(async (data) => {
          console.log(data.data);
          let msg = "";
          let path = data.data[0].path;
          // let path = CLOUD_URL + data.data[0].path;
          // if (file.type.includes("audio")) {
          //   msg = `<audio controls src="${path}"></audio>`;
          // } else if (file.type.includes("video")) {
          //   msg = `<video controls src="${path}"></video>`;
          // }
          if (data.data[0].status) {
            await connection!.send(
              "SendPrivateMessage",
              path,
              senderId,
              receiverId,
              receiverAccountType,
              confirmationNumber,
              senderAccountType,
              file.type,
              0
            );
          } else {
            toast.error(data.data[0].message);
          }
        });
      }
    };
  };

  const sendAudioVideoGroup = (
    file: Blob,
    chatGroupId: number,
    senderId: number,
    senderAccountTypeId: number
  ) => {
    console.log(file, chatGroupId, senderId, senderAccountTypeId);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result && typeof reader.result == "string") {
        const chatUploadMedia = {
          File: reader.result,
          SenderId: senderId,
          SenderAccountTypeId: senderAccountTypeId,
          chatGroupId: chatGroupId,
          IsGroup: true,
        };
        console.log(chatUploadMedia);
        createCommonAPICall({
          requestBody: chatUploadMedia,
          apiService: dashboardService.uploadAudioVideoForChat,
          showSuccessMessage: false,
          showErrorMessage: false,
          setSuccessErrorContext,
        }).then(async (data) => {
          console.log(data.data);
          let message = data.data[0].path;
          if (data.data[0].status) {
            await connection!.send(
              "SendGroupMessage",
              chatGroupId,
              message,
              senderId,
              senderAccountTypeId,
              file.type,
              0
            );
          } else {
            toast.error(data.data[0].message);
          }
        });
      }
    };
  };

  const keyDownHandler = async (
    event: React.KeyboardEvent<HTMLInputElement>,
    message: string,
    senderId: number,
    receiverId: number,
    receiverAccountType: AccountType,
    confirmationNumber: string,
    senderAccountType: AccountType
  ) => {
    console.log("keyDownHandler");
    if (event.code === "Enter" && message !== "") {
      await connection!.send(
        "SendPrivateMessage",
        message,
        senderId,
        receiverId,
        receiverAccountType,
        confirmationNumber,
        senderAccountType,
        "",
        0
      );
    }
  };

  const updateChatBoxClasses = (openChatBoxList: IChatBoxInfo[]) => {
    const openChatBoxes = openChatBoxList.filter((box) => !box.isMinimized);
    openChatBoxes.length > 0
      ? isChatOpend
        ? document.body.classList.toggle("chatbox-open", true)
        : document.body.classList.toggle("chatbox-open", false)
      : document.body.classList.toggle("chatbox-open", false);
  };

  const updateGroupChatBoxClasses = (
    openGroupChatBoxList: IGroupChatBoxInfo[]
  ) => {
    const openGroupChatBoxes = openGroupChatBoxList.filter(
      (box) => !box.isMinimized
    );
    openGroupChatBoxes.length > 0
      ? isChatOpend
        ? document.body.classList.toggle("chatbox-open", true)
        : document.body.classList.toggle("chatbox-open", false)
      : document.body.classList.toggle("chatbox-open", false);
  };

  const handleConnection = () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  };

  // Use effects
  useEffect(() => {
    showLoader();
    handleConnection();
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");
          connection.on("setChatList", (data) => {
            console.log(data);
            setChatList(data.chatHistory);
            hideLoader();
          });

          connection.on("SetChatHistoryList", (data) => {
            console.log(data);
            setIsChatOpend(true);
            if (data) setOpenChatBoxList(data.chatresponse);
            const request = data.chatresponse;
            const openChatBoxes = openChatBoxList.filter(
              (box) =>
                box.receiverId === request.recieverId &&
                box.senderId === request.senderId
            );
            if (!openChatBoxes || openChatBoxes.length === 0) {
              setOpenChatBoxList([
                ...openChatBoxList,
                {
                  receiverId: request.receiverId,
                  receiverName: request.receiverName,
                  senderId: request.senderId,
                  senderName: request.senderName,
                  isMinimized: false,
                  confirmationNumber: request.confirmationNumber,
                  isAdmin: request.isAdmin != 0,
                  chatView: data.chatView,
                },
              ]);
              hideLoader();
              return;
            }
            const chatBoxes = openChatBoxList.filter(
              (box) =>
                box.receiverId !== request.receiverId ||
                box.senderId !== request.senderId
            );
            setOpenChatBoxList([
              ...chatBoxes,
              {
                receiverId: request.receiverId,
                receiverName: request.receiverName,
                senderId: request.senderId,
                senderName: request.senderName,
                isMinimized: false,
                confirmationNumber: request.confirmationNumber,
                isAdmin: request.isAdmin != 0,
                chatView: data.chatView,
              },
            ]);
          });

          connection.on("openGroupChatBox", (data) => {
            console.log(data);
            setIsChatOpend(true);
            setOpenGroupChatBoxList([data]);
          });

          if (senderId == 0) {
            createCommonAPICall({
              apiService: dashboardService.getLoggedInUserId,
              isHideLoader: false,
            }).then((data) => {
              if (data.isSuccessfull) {
                console.log(data);
                setSenderId(data.data.id);
                connection!.send(
                  "GetChatList",
                  0,
                  contextUser.userRoleId,
                  AccountType.Physician,
                  data.data.id,
                  null,
                  null
                );
              }
            });
          } else {
            connection!.send(
              "GetChatList",
              0,
              contextUser.userRoleId,
              AccountType.Physician,
              senderId,
              null,
              null
            );
          }
        })
        .catch((e) => {
          hideLoader();
          console.log("Connection failed: ", e);
          navigate(0);
        });
    }
  }, [connection]);

  useEffect(() => {
    getChatList();
    updateChatBoxClasses(openChatBoxList);
    if (connection)
      connection.on("SendPrivateMessage", (data) => handlePrivateMessage(data));
  }, [openChatBoxList]);

  useEffect(() => {
    getChatList();
    updateGroupChatBoxClasses(openGroupChatBoxList);
    if (connection)
      connection.on("sendGroupMessage", (data) => {
        console.log(data);
        setOpenGroupChatBoxList([data]);
      });
  }, [openGroupChatBoxList]);

  const handlePrivateMessage = (data: any) => {
    console.log("SendPrivateMessage", data, openChatBoxList);
    if (
      openChatBoxList.find(
        (chat) =>
          chat.confirmationNumber == data.chatresponse.confirmationNumber
      )
    ) {
      if (data) setOpenChatBoxList(data.chatresponse);
      const request = data.chatresponse;
      const openChatBoxes = openChatBoxList.filter(
        (box) =>
          box.receiverId === request.receiverId &&
          box.senderId === request.senderId
      );
      if (!openChatBoxes || openChatBoxes?.length === 0) {
        setOpenChatBoxList([
          ...openChatBoxList,
          {
            receiverId: request.receiverId,
            receiverName: request.receiverName,
            senderId: request.senderId,
            senderName: request.senderName,
            isMinimized: false,
            confirmationNumber: request.confirmationNumber,
            isAdmin: request.isAdmin != 0,
            chatView: data.chatView,
          },
        ]);
        hideLoader();
        return;
      }
      const chatBoxes = openChatBoxList.filter(
        (box) =>
          box.receiverId !== request.receiverId ||
          box.senderId !== request.senderId
      );
      setOpenChatBoxList([
        ...chatBoxes,
        {
          receiverId: request.receiverId,
          receiverName: request.receiverName,
          senderId: request.senderId,
          senderName: request.senderName,
          isMinimized: false,
          confirmationNumber: request.confirmationNumber,
          isAdmin: request.isAdmin != 0,
          chatView: data.chatView,
        },
      ]);
    }
  };

  useEffect(() => {
    getChatList();
  }, [recieverType]);

  return (
    <Box>
      <div className="chatbox-overlay"></div>
      <main className="main-content">
        <div
          className="overlay"
          onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
        ></div>
        <Box className="request-container-box">
          <div className="request-header">
            <Typography variant="h2">Chat History</Typography>
            <Button variant="outlined" onClick={handleOnClickBackCancel}>
              <img src={ArrowBack} alt="arrow" />
              Back
            </Button>
          </div>
          <Box className="request-box">
            <div className="request-header responsive-header">
              <Typography variant="h4">
                Chats ({AccountType[recieverType]})
              </Typography>
              <div className="small-center request-btn-group">
                <Tooltip title="Get Chats with Physician">
                  <Button
                    variant={
                      recieverType == AccountType.Physician
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setRecieverType(AccountType.Physician);
                    }}
                  >
                    Physician
                  </Button>
                </Tooltip>
                <Tooltip title="Get Chats with Admin">
                  <Button
                    variant={
                      recieverType == AccountType.Admin
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setRecieverType(AccountType.Admin);
                    }}
                  >
                    Admin
                  </Button>
                </Tooltip>
                <Tooltip title="Get Chat Groups">
                  <Button
                    variant={
                      recieverType == AccountType.Groups
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setRecieverType(AccountType.Groups);
                    }}
                  >
                    Groups
                  </Button>
                </Tooltip>
              </div>
            </div>
            <ChatList
              chatList={chatList}
              handleOpenChatClick={handleOpenChatClick}
            />
          </Box>
        </Box>
      </main>

      {isChatOpend &&
        openChatBoxList.map((chatBox, index) => {
          console.log(openChatBoxList);
          return (
            <ChatBox
              index={index}
              handleOnMinimized={handleOnMinimized}
              key={index}
              handleOnCloseChat={handleOnCloseChat}
              chatBoxInfo={chatBox}
              sendMessage={sendMessage}
              sendAudioVideo={sendAudioVideo}
              onKeyHandler={keyDownHandler}
            />
          );
        })}

      {isChatOpend &&
        openGroupChatBoxList.map((chatBox, index) => {
          return (
            <GroupChatBox
              index={index}
              key={index}
              groupChatBoxInfo={chatBox}
              handleOnMinimized={handleOnGroupChatMinimized}
              handleOnCloseChat={handleOnCloseGroupChat}
              handleSendGroupMessage={handleSendGroupMessage}
              sendAudioVideo={sendAudioVideoGroup}
            />
          );
        })}
    </Box>
  );
};

export default Chats;
