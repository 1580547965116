import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
} from "@mui/material";
import { CloseWhiteIcon } from "../../assests/images";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle } from "../../utility/helpers";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";

type ComponentProps = {
	phoneTypeModelOpen: boolean;
	handlePhoneTypeModelClose: any;
	onSelectPhoneType: any;
};

export function PhoneTypeModal(props: ComponentProps) {
	// Usestates
	const [phoneType, setphoneType] = useState("M");

	//Page level local variable
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

	// Extract Props
	const { phoneTypeModelOpen, handlePhoneTypeModelClose } = props;

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		reset,
	} = useForm();

	// Events and functions
	const handleOnClickConfirm = async () => {
		setphoneType(phoneType);
		handlePhoneTypeModelClose();
	};

	// useEffects
	useEffect(() => {
		reset();
		clearErrors();
		setphoneType("M");
	}, [phoneTypeModelOpen]);

	const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setphoneType(event.target.value);
		props.onSelectPhoneType(event.target.value);
	};

	return (
		<>
			<Modal
				open={phoneTypeModelOpen}
				onClose={handlePhoneTypeModelClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Select type of Phone number</Typography>
						<IconButton onClick={handlePhoneTypeModelClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						<div>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label">
									Phone Number
								</FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									defaultValue="M"
									name="radio-buttons-group"
									value={phoneType}
									onChange={onRadioChange}
								>
									<FormControlLabel
										value="M"
										control={<Radio />}
										label="Mobile"
									/>
									<FormControlLabel
										value="L"
										control={<Radio />}
										label="Landline"
									/>
								</RadioGroup>
							</FormControl>
							<Typography variant="subtitle2">*In Case of Landline number, confirmation will be sent by email.</Typography>
						</div>
						<div className="modal-footer">
							<Tooltip title="Confirm">
								<Button
									variant="contained"
									onClick={handleSubmit(handleOnClickConfirm)}
								>
									Select
								</Button>
							</Tooltip>
							<Tooltip title="Cancel">
								<Button variant="outlined" onClick={handlePhoneTypeModelClose}>
									Cancel
								</Button>
							</Tooltip>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
