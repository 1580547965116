import { AxiosResponse } from "axios";
import {
  IHttpsResponse,
  ILoginRequest,
  ILoginResponse,
} from "../utility/interfaces";
import { UnProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const generateDefaultToken = async (): Promise<
  AxiosResponse<IHttpsResponse<ILoginResponse>>
> =>
  httpClient.get<IHttpsResponse<ILoginResponse>>(
    UnProtectedEndPoints.GenerateDefaultToken
  );

const patientLogin = async (
  request: ILoginRequest
): Promise<AxiosResponse<IHttpsResponse<ILoginResponse>>> =>
  httpClient.post<IHttpsResponse<ILoginResponse>>(
    UnProtectedEndPoints.PatientLogin,
    request
  );

const forgotPassword = async (
  userName: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(UnProtectedEndPoints.ForgotPassword, {
    userName,
  });

const resetPasswordVerifyLink = async (
  linkToVerify: string | null
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.ResetPasswordVerifyLink,
    { linkToVerify }
  );

const resetPassword = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.ResetPassword,
    request
  );

const checkRegisteredUser = (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.CheckRegisteredUser,
    request
  );

const createAccount = (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.CreateAccount,
    request
  );

export default {
  generateDefaultToken,
  patientLogin,
  forgotPassword,
  resetPasswordVerifyLink,
  resetPassword,
  checkRegisteredUser,
  createAccount,
};
