import { ArrowBack, Logo } from "../../assests/images";
import "./loader-style.css";
import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import { hideLoader } from "../../utility/helpers";

export function PageNotFound() {
	const navigate = useNavigate();
	useEffect(() => {
		hideLoader();
	}, []);
	return (
		<div className="loader-wrapper">
			<div className="loader-inner">
				<img className="loader-icon tree-mask" src={Logo} alt="" />
				<Typography className="pt-2 color-white" variant="h5">
					Page not found (404)
				</Typography>
				<Button
					onClick={() => {
						navigate(AppRoutings.Root);
					}}
					className="back-login font-size-20"
				>
					Home
				</Button>
				<Button
					onClick={() => {
						navigate(-1);
					}}
					className="back-login font-size-20"
				>
					<img src={ArrowBack} alt="arrow" />
					Back
				</Button>
			</div>
		</div>
	);
}
