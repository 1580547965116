import { Outlet } from "react-router-dom";
import HeaderType1 from "./header-type-1";
import Footer from "./footer";

const LayoutType1 = () => {
  return (
    <>
      <HeaderType1 />
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutType1;
