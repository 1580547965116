import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IValidationParms } from "../../utility/interfaces/common/validation-parms";
import { IConciergeDetails } from "../../utility/interfaces/create-request/create-request-details";
import PatientDetails from "./patient-details";
import { CreateRequestType } from "../../utility/enums/create-request";
import { useContext, useEffect, useState } from "react";
import { WarningModal } from "../common/warning-modal";
import createRequestService from "../../services/create-request-service";
import {
  createCommonAPICall,
  hideLoader,
  openSucessErrorModal,
} from "../../utility/helpers";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import { IRegions } from "../../utility/interfaces";
import { PhoneTypeModal } from "../common/phonetype-modal";
import Geocode from "react-geocode";
import { AppRoutings } from "../../utility/enums/app-routings";
import { useNavigate } from "react-router-dom";
import { LocationIcon } from "../../assests/images";
import CustomIntlTelInput from "../common/custom-intl-tel-input";
import dashboardService from "../../services/dashboard-service";
import { Constants } from "utility/enums/constants";

type ComponentProps = {
  details: IConciergeDetails;
  onChangeDetails: any;
  validationParams: IValidationParms;
  handleOnBackButtonClick: any;
};

const ConciergeForm = (props: ComponentProps) => {
  // page level variables
  const navigate = useNavigate();

  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
  Geocode.setApiKey(Constants.GeocodeKey);

  // use state
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [stateResponse, setStateResponse] = useState<IRegions[]>([]);
  const [txtstate, setTxtState] = useState<string[]>([]);
  const [phoneTypeModelOpen, setphoneTypeModelOpen] = useState(false);
  const [responsibleParty, setResponsibleParty] = useState("P");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isRequestorPhoneNumberValid, setIsRequestorPhoneNumberValid] =
    useState(true);

  // Handled events
  const getRegions = async () => {
    const data = await createCommonAPICall({
      apiService: createRequestService.getRegions,
      showSuccessMessage: false,
      showErrorMessage: true,
      setSuccessErrorContext,
    });
    console.log(data.data);
    if (data && data.data) {
      for (let i = 0; i < data.data.length; i++) {
        txtstate.push(data.data[i].name);
      }
      setStateResponse(data.data);
    }
  };

  const handlePhoneTypeModelOpen = () => setphoneTypeModelOpen(true);

  const handlePhoneTypeModelClose = () => setphoneTypeModelOpen(false);

  const onRadioChange = (event: any) => {
    onChangeDetails("radioRequestorPhoneType", event);
  };

  const onChangePatientDetails = (name: string, value: any) => {
    const patientTempDetails = { ...details.patientDetails, [name]: value };
    onChangeDetails("patientDetails", patientTempDetails);
  };
  const handleOnClickSubmit = () => {
    console.log("Click on submit patient form");
    if (validateResponsibleParty()) {
      var address =
        details.patientDetails.street +
        " " +
        details.patientDetails.city +
        ", " +
        details.patientDetails.state +
        " " +
        details.patientDetails.zipCode;

      if (
        new Date(details.patientDetails.birthDate!).toLocaleString('en-US') !==
        "Invalid Date"
      ) {
        Geocode.fromAddress(address).then(
          async (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            console.log(lat, lng, response);
            var isValid = await CheckValidRegion(response.results[0]);
            if (isValid) {
              console.log("Valid address.");
              const requestBody = {
                requestTypeId: CreateRequestType.concierge,
                requestorFirstName: details.conciergeFirstName,
                requestorLastName: details.conciergeLastName,
                requestorPhoneNumber: details.conciergePhoneNumber,
                requestorEmail: details.conciergeEmail,
                conciergeName: details.hotelPropertyName,
                clientNotes: details.patientDetails.symptoms,
                firstName: details.patientDetails.firstName,
                lastName: details.patientDetails.lastName,
                // dateOfBirth: new Date(details.patientDetails.birthDate),
                dateOfBirth: new Date(details.patientDetails.birthDate),
                strDateOfBirth: new Date(
                  details.patientDetails.birthDate!
                ).toLocaleString('en-US'),
                phoneNumber: details.patientDetails.phoneNumber,
                email: details.patientDetails.email,
                street: details.patientDetails.street,
                city: details.patientDetails.city,
                state: details.patientDetails.state,
                zipCode: details.patientDetails.zipCode,
                room: details.patientDetails.room,
                radioClientPhoneType:
                  details.patientDetails.radioClientPhoneType,
                radioRequestorPhoneType: details.radioRequestorPhoneType,
                clientRegionId: details.patientDetails.clientRegionId,
              };

              console.log(requestBody);

              const data = await createCommonAPICall({
                requestBody,
                apiService: createRequestService.createOtherTypeRequest,
                showErrorMessage: true,
                showSuccessMessage: false,
                setSuccessErrorContext,
              });

              if (data && data.isSuccessfull) {
                navigate(AppRoutings.RequestSuccess, {
                  state: {
                    message: data.message,
                    requestTypeId: requestBody.requestTypeId,
                  },
                });
              }
            } else {
              hideLoader();
              openSucessErrorModal(
                setSuccessErrorContext,
                "Error",
                "At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
                false
              );
            }
          },
          (error) => {
            openSucessErrorModal(
              setSuccessErrorContext,
              "Error",
              "At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
              false
            );
          }
        );
      } else {
        hideLoader();
        openSucessErrorModal(
          setSuccessErrorContext,
          "Error",
          "Please enter a valid birthdate (MM/DD/YYYY)",
          false
        );
      }
    } else {
      setWarningModalOpen(true);
    }
  };

  const CheckValidRegion = async (places: any) => {
    // get state from address
    var isValidState = false;
    var stateName;
    for (var component in places["address_components"]) {
      for (var i in places["address_components"][component]["types"]) {
        if (
          places["address_components"][component]["types"][i] ==
          "administrative_area_level_1"
        ) {
          stateName = places["address_components"][component]["long_name"];
          for (var j = 0; j < txtstate.length; j++) {
            if (stateName == txtstate[j]) {
              isValidState = true;
              for (let s = 0; s < stateResponse.length; s++) {
                if (stateResponse[s].name == stateName) {
                  details.patientDetails.clientRegionId =
                    stateResponse[s].regionId;
                }
              }
            }
          }
        }
      }
    }
    if (isValidState && isValidState == true) {
      const zipCodesRes = await createCommonAPICall({
        apiService: dashboardService.getAllZipCodes,
        showErrorMessage: true,
        showSuccessMessage: false,
        setSuccessErrorContext,
      });

      if (zipCodesRes && zipCodesRes.isSuccessfull) {
        console.log(zipCodesRes);
        if (
          !zipCodesRes.data.some(
            (zip: any) => zip.zipCode == details.patientDetails.zipCode
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else {
      console.log(
        "At this time, we" +
          "'" +
          "re only able to service select areas in DC, Maryland, Virginia, and New York City."
      );
    }
  };

  const validateResponsibleParty = () => {
    if (
      (details.conciergeEmail == details.patientDetails.email ||
        details.conciergePhoneNumber == details.patientDetails.phoneNumber) &&
      responsibleParty != "Y"
    ) {
      return false;
    }
    return true;
  };

  const handleWarningModalClose = () => {
    setWarningModalOpen(false);
  };

  const onSelectResponsibleParty = (event: any) => {
    setResponsibleParty(event);
  };

  const handleOnClickOpenMap = () => {
    let address = `${details.patientDetails.street} ${details.patientDetails.city} ${details.patientDetails.state} ${details.patientDetails.zipCode}`;
    const url = `http://www.google.com/maps?q=${address}`;
    window.open(url, "_blank");
  };

  const handlePhoneNumberChange = (
    isValid: any,
    phoneNumber: any,
    countryData: any
  ) => {
    setIsRequestorPhoneNumberValid(isValid);
    let formattedPhoneNumber =
      phoneNumber != ""
        ? phoneNumber.includes("+")
          ? phoneNumber
          : `+${countryData.dialCode} ${phoneNumber}`
        : "";
    onChangeDetails("conciergePhoneNumber", formattedPhoneNumber);
  };

  useEffect(() => {
    setWarningModalOpen(true);
    getRegions();
  }, []);

  // Extract Props
  const {
    details,
    onChangeDetails,
    validationParams,
    handleOnBackButtonClick,
  } = props;

  return (
    <>
      <Box>
        <Box className="request-box">
          <Typography variant="h4">Concierge Information</Typography>
          <div>
            <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  {...validationParams?.register("conciergeFirstName")}
                  error={
                    validationParams?.errors?.conciergeFirstName?.message
                      ? true
                      : false
                  }
                  helperText={validationParams?.errors?.conciergeFirstName?.message?.toString()}
                  name="conciergeFirstName"
                  id="conciergeFirstName"
                  label="Your First Name"
                  value={details.conciergeFirstName}
                  fullWidth
                  onChange={(e) =>
                    onChangeDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("conciergeLastName")}
                  error={
                    validationParams?.errors?.conciergeLastName?.message
                      ? true
                      : false
                  }
                  helperText={validationParams?.errors?.conciergeLastName?.message?.toString()}
                  name="conciergeLastName"
                  id="conciergeLastName"
                  label="Your Last Name"
                  value={details.conciergeLastName}
                  fullWidth
                  onChange={(e) =>
                    onChangeDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomIntlTelInput
                  isPhoneNumberValid={isRequestorPhoneNumberValid}
                  onChange={handlePhoneNumberChange}
                  onBlur={() => {
                    if (details.conciergePhoneNumber != "")
                      handlePhoneTypeModelOpen();
                  }}
                  value={details.conciergePhoneNumber}
                  name="conciergePhoneNumber"
                />

                {/* <TextField
                  {...validationParams?.register("conciergePhoneNumber")}
                  error={
                    validationParams?.errors?.conciergePhoneNumber?.message
                      ? true
                      : false
                  }
                  helperText={validationParams?.errors?.conciergePhoneNumber?.message?.toString()}
                  name="conciergePhoneNumber"
                  id="conciergePhoneNumber"
                  label="Your Phone Number"
                  value={details.conciergePhoneNumber}
                  fullWidth
                  onChange={(e) =>
                    onChangeDetails(e.target.name, e.target.value)
                  }
                  onBlurCapture={() => {
                    if (details.conciergePhoneNumber != "")
                      handlePhoneTypeModelOpen();
                  }}
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("conciergeEmail")}
                  error={
                    validationParams?.errors?.conciergeEmail?.message
                      ? true
                      : false
                  }
                  helperText={validationParams?.errors?.conciergeEmail?.message?.toString()}
                  name="conciergeEmail"
                  id="conciergeEmail"
                  label="Your Email"
                  value={details.conciergeEmail}
                  fullWidth
                  onChange={(e) =>
                    onChangeDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("hotelPropertyName")}
                  error={
                    validationParams?.errors?.hotelPropertyName?.message
                      ? true
                      : false
                  }
                  helperText={validationParams?.errors?.hotelPropertyName?.message?.toString()}
                  name="hotelPropertyName"
                  id="hotelPropertyName"
                  label="Hotel/Property Name"
                  value={details.hotelPropertyName}
                  fullWidth
                  onChange={(e) =>
                    onChangeDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </div>
          <Typography variant="h4">Concierge Location</Typography>
          <div>
            <Grid container columnSpacing={{ sm: 2, lg: 3 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("street")}
                  error={
                    validationParams?.errors?.street?.message ? true : false
                  }
                  helperText={validationParams?.errors?.street?.message?.toString()}
                  name="street"
                  id="street"
                  label="Street"
                  value={details.patientDetails.street}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("city")}
                  error={validationParams?.errors?.city?.message ? true : false}
                  helperText={validationParams?.errors?.city?.message?.toString()}
                  name="city"
                  id="city"
                  label="City"
                  value={details.patientDetails.city}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("state")}
                  error={
                    validationParams?.errors?.state?.message ? true : false
                  }
                  helperText={validationParams?.errors?.state?.message?.toString()}
                  name="state"
                  id="state"
                  label="State"
                  value={details.patientDetails.state}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...validationParams?.register("zipCode")}
                  error={
                    validationParams?.errors?.zipCode?.message ? true : false
                  }
                  helperText={validationParams?.errors?.zipCode?.message?.toString()}
                  name="zipCode"
                  id="zipCode"
                  label="Zip Code"
                  value={details.patientDetails.zipCode}
                  fullWidth
                  onChange={(e) =>
                    onChangePatientDetails(e.target.name, e.target.value)
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <div className="verify-btn-group">
                  <Tooltip title={"Open Map"}>
                    <Button variant="outlined" onClick={handleOnClickOpenMap}>
                      <img src={LocationIcon} alt="location" />
                      Map
                    </Button>
                  </Tooltip>
                </div>
              </Grid> */}
            </Grid>
          </div>
          <PatientDetails
            validationParams={validationParams}
            patientDetails={details.patientDetails}
            onChangePatientDetails={onChangePatientDetails}
            isShowUploadOption={false}
            requestTypeId={CreateRequestType.concierge}
            onSelectResponsibleParty={onSelectResponsibleParty}
            isPhoneNumberValid={isPhoneNumberValid}
            setIsPhoneNumberValid={setIsPhoneNumberValid}
          />
          <div className="request-btn-group">
            <Tooltip title="Click Here To Submit">
              <Button
                variant="contained"
                onClick={() => {
                  if (
                    details.patientDetails.phoneNumber === "" ||
                    details.conciergePhoneNumber === "" ||
                    !isPhoneNumberValid ||
                    !isRequestorPhoneNumberValid
                  ) {
                    if (details.patientDetails.phoneNumber === "")
                      setIsPhoneNumberValid(false);
                    if (details.conciergePhoneNumber === "")
                      setIsRequestorPhoneNumberValid(false);
                    validationParams.handleSubmit(() =>
                      console.log("phone number required")
                    )();
                  } else {
                    validationParams.handleSubmit(handleOnClickSubmit, () => {
                      console.log(
                        "Errors: ",
                        validationParams.errors.birthDate
                      );
                      console.log("Form values: ", details);
                    })();
                  }
                }}
              >
                Submit
              </Button>
            </Tooltip>
            <Tooltip title="Click Here To Cancel">
              <Button onClick={handleOnBackButtonClick} variant="outlined">
                Cancel
              </Button>
            </Tooltip>
          </div>
        </Box>
      </Box>
      <WarningModal
        isModalOpen={warningModalOpen}
        handleOnClickCloseModal={handleWarningModalClose}
        handleOnClickOk={() => {}}
        warningMessage={
          "When submitting a request, you must provide the correct contact information for the patient or the responsibly party. Failure to provide the correct email and phone number will delay service or be declined."
        }
        title={"Information"}
        okButtonText={"Ok"}
        closeButtonText={undefined}
      />
      <PhoneTypeModal
        phoneTypeModelOpen={phoneTypeModelOpen}
        handlePhoneTypeModelClose={handlePhoneTypeModelClose}
        onSelectPhoneType={onRadioChange}
      ></PhoneTypeModal>
    </>
  );
};

export default ConciergeForm;
