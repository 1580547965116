import { AxiosResponse } from "axios";
import { IRegions, IHttpsResponse } from "../utility/interfaces";
import { ProtectedEndPoints, UnProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const getRegions = async (
  request: IRegions
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.GetRegions,
    request
  );

const createRequest = async (
  request: IRegions
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.CreateRequest,
    request
  );

const createOtherTypeRequest = async (
  request: IRegions
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.CreateOtherTypeRequest,
    request
  );

const uploadFileForRequest = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.UploadFileForRequest,
    request
  );

const checkAgreementLinkValidity = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.CheckAgreementLinkValidity,
    request
  );

const acceptOrRejectAgreement = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    UnProtectedEndPoints.AcceptOrRejectAgreement,
    request
  );

export default {
  getRegions,
  createRequest,
  uploadFileForRequest,
  createOtherTypeRequest,
  checkAgreementLinkValidity,
  acceptOrRejectAgreement,
};
