import * as yup from "yup";
import { phoneNumberRegExp } from "../helpers/regular-expressions";

const commonObject = {
  symptoms: yup.string().required("Please provide your symptoms."),
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  email: yup
    .string()
    .email("Email is not valid.")
    .required("Email is required."),
  // phoneNumber: yup
  //   .string()
  //   .required("Phone number is required."),
  street: yup.string().required("Street is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  zipCode: yup.string().required("Zip code is required."),
  birthDate: yup
    .date()
    .typeError("Enter valid date (MM/DD/YYYY).")
    .required("Date of birth is required."),
};
export const patientFormSchema = yup.object().shape(commonObject);

export const familyFriendFormSchema = yup.object().shape({
  friendFirstName: yup.string().required("First name is required."),
  friendLastName: yup.string().required("Last name is required."),
  // friendPhoneNumber: yup
  //   .string()
  //   .required("Phone number is required.")
  //   .matches(phoneNumberRegExp, "Enter valid phone number."),
  friendEmail: yup
    .string()
    .email("Email is not valid.")
    .required("Email is required."),
  ...commonObject,
});

export const conciergeFormSchema = yup.object().shape({
  conciergeFirstName: yup.string().required("First name is required."),
  conciergeLastName: yup.string().required("Last name is required."),
  // conciergePhoneNumber: yup
  //   .string()
  //   .required("Phone number is required.")
  //   .matches(phoneNumberRegExp, "Enter valid phone number."),
  conciergeEmail: yup
    .string()
    .email("Email is not valid.")
    .required("Email is required."),
  hotelPropertyName: yup.string().required("Hotel/property name is required."),
  ...commonObject,
});

export const businessPartnerFormSchema = yup.object().shape({
  businessPartnerFirstName: yup.string().required("First name is required."),
  businessPartnerLastName: yup.string().required("Last name is required."),
  // businessPartnerPhoneNumber: yup
  //   .string()
  //   .required("Phone number is required.")
  //   .matches(phoneNumberRegExp, "Enter valid phone number."),
  businessPartnerEmail: yup
    .string()
    .email("Email is not valid.")
    .required("Email is required."),
  businessPropertyName: yup
    .string()
    .required("Business/property name is required."),
  ...commonObject,
});

export const cancelAgreementFormSchema = yup.object().shape({
  notes: yup.string().required("PLease provide Cancellation reason."),
});
