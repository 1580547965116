import { Box, Button, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IValidationParms } from "../../utility/interfaces/common/validation-parms";
import { IPatientDetails } from "../../utility/interfaces/create-request/create-request-details";
import PatientDetails from "./patient-details";
import { newPasswordSchema } from "../../utility/validations";
import Geocode from "react-geocode";
import { IRegions } from "../../utility/interfaces";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import {
  createCommonAPICall,
  hideLoader,
  openSucessErrorModal,
} from "../../utility/helpers";
import createRequestService from "../../services/create-request-service";
import { CreateRequestType } from "../../utility/enums/create-request";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import dashboardService from "../../services/dashboard-service";
import { Constants } from "utility/enums/constants";

type ComponentProps = {
  patientDetails: IPatientDetails;
  onChangePatientDetails: any;
  validationParams: IValidationParms;
  handleOnBackButtonClick: any;
};

const PatientForm = (props: ComponentProps) => {
  //page level variables
  const navigate = useNavigate();

  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
  Geocode.setApiKey(Constants.GeocodeKey);

  // Extract Props
  const {
    patientDetails,
    onChangePatientDetails,
    validationParams,
    handleOnBackButtonClick,
  } = props;

  // use state
  const [newUser, setNewUser] = useState(false);
  const [stateResponse, setStateResponse] = useState<IRegions[]>([]);
  const [txtstate, setTxtState] = useState<string[]>([]);
  const [requestGuid, setRequestGuid] = useState("");
  const [fileName, setFileName] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  // Handled event(s)
  const getRegions = async () => {
    const data = await createCommonAPICall({
      apiService: createRequestService.getRegions,
      showSuccessMessage: false,
      showErrorMessage: true,
      setSuccessErrorContext,
    });
    console.log(data.data);
    if (data && data.data) {
      for (let i = 0; i < data.data.length; i++) {
        txtstate.push(data.data[i].name);
      }
      setStateResponse(data.data);
    }
  };

  const onSelectNewFiles = (event: any) => {
    const fileNames: string[] = [];
    if (event.target.files && event.target.files.length > 0) {
      Array.prototype.forEach.call(event.target.files, (file: any) => {
        fileNames.push(file.name);
      });
      onChangePatientDetails("documentName", fileNames?.toString());
    }
  };
  const handleOnClickSubmit = async () => {
    console.log("Click on submit patient form");
    if (!newUser || (await validatePassword())) {
      var address =
        patientDetails.street +
        " " +
        patientDetails.city +
        ", " +
        patientDetails.state +
        " " +
        patientDetails.zipCode;

      Geocode.fromAddress(address).then(
        async (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng, response);
          var isValid = await CheckValidRegion(response.results[0]);
          if (isValid) {
            console.log("Valid address.");
            if (
              new Date(patientDetails.birthDate!).toLocaleString('en-US') !==
              "Invalid Date"
            ) {
              console.log("Submittd values: ", patientDetails);

              const requestBody = {
                requestTypeId: CreateRequestType.patient,
                clientNotes: patientDetails.symptoms,
                firstName: patientDetails.firstName,
                lastName: patientDetails.lastName,
                // dateOfBirth: new Date(patientDetails.birthDate),
                dateOfBirth: new Date(patientDetails.birthDate),
                strDateOfBirth: new Date(
                  patientDetails.birthDate!
                ).toLocaleString('en-US'),
                phoneNumber: patientDetails.phoneNumber,
                email: patientDetails.email,
                isNewUser: newUser,
                password: newUser ? patientDetails.password : "",
                street: patientDetails.street,
                city: patientDetails.city,
                state: patientDetails.state,
                zipCode: patientDetails.zipCode,
                room: patientDetails.room,
                radioClientPhoneType: patientDetails.radioClientPhoneType,
                radioRequestorPhoneType: patientDetails.radioClientPhoneType,
                clientRegionId: patientDetails.clientRegionId,
                requestGuid: requestGuid,
                fileName: fileName,
              };

              const data = await createCommonAPICall({
                requestBody,
                apiService: createRequestService.createRequest,
                showErrorMessage: true,
                showSuccessMessage: false,
                setSuccessErrorContext,
              });

              if (data && data.isSuccessfull) {
                navigate(AppRoutings.RequestSuccess, {
                  state: {
                    message: data.message,
                    requestTypeId: requestBody.requestTypeId,
                  },
                });
              }
            } else {
              hideLoader();
              openSucessErrorModal(
                setSuccessErrorContext,
                "Error",
                "Please enter a valid birthdate (MM/DD/YYYY)",
                false
              );
            }
          } else {
            hideLoader();
            openSucessErrorModal(
              setSuccessErrorContext,
              "Error",
              "At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
              false
            );
          }
        },
        (error) => {
          openSucessErrorModal(
            setSuccessErrorContext,
            "Error",
            "At this time, we are only able to service select areas in DC, Maryland, Virginia, and New York City.",
            false
          );
        }
      );
    }
  };

  const CheckValidRegion = async (places: any) => {
    // get state from address
    var isValidState = false;
    var stateName;
    for (var component in places["address_components"]) {
      for (var i in places["address_components"][component]["types"]) {
        if (
          places["address_components"][component]["types"][i] ==
          "administrative_area_level_1"
        ) {
          stateName = places["address_components"][component]["long_name"];
          for (var j = 0; j < txtstate.length; j++) {
            if (stateName == txtstate[j]) {
              isValidState = true;
              for (let s = 0; s < stateResponse.length; s++) {
                if (stateResponse[s].name == stateName) {
                  patientDetails.clientRegionId = stateResponse[s].regionId;
                }
              }
            }
          }
        }
      }
    }
    if (isValidState && isValidState == true) {
      const zipCodesRes = await createCommonAPICall({
        apiService: dashboardService.getAllZipCodes,
        showErrorMessage: true,
        showSuccessMessage: false,
        setSuccessErrorContext,
      });

      if (zipCodesRes && zipCodesRes.isSuccessfull) {
        console.log(zipCodesRes);
        if (
          !zipCodesRes.data.some(
            (zip: any) => zip.zipCode == patientDetails.zipCode
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else {
      console.log(
        "At this time, we" +
          "'" +
          "re only able to service select areas in DC, Maryland, Virginia, and New York City."
      );
    }
  };

  const validatePassword = async () => {
    const data = validationParams.getValues();
    try {
      await newPasswordSchema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (error: any) {
      console.log("Error");
      error.inner?.map((inner: any, index: any) => {
        const { type, path, errors } = inner;
        return validationParams.setError(path, {
          type,
          message: errors[index],
        });
      });
      return false;
    }
  };

  // useEffects
  useEffect(() => {
    const firstElement = document.getElementById("symptoms");
    firstElement?.focus();
    getRegions();
  }, []);

  return (
    <Box>
      <Box className="request-box">
        <PatientDetails
          validationParams={validationParams}
          patientDetails={patientDetails}
          onChangePatientDetails={onChangePatientDetails}
          setNewUser={setNewUser}
          setRequestGuid={setRequestGuid}
          setFileName={setFileName}
          requestTypeId={CreateRequestType.patient}
          isPhoneNumberValid={isPhoneNumberValid}
          setIsPhoneNumberValid={setIsPhoneNumberValid}
        />
        <div className="request-btn-group">
          <Tooltip title="Click Here To Submit">
            <Button
              onClick={() => {
                console.log(patientDetails);
                if (patientDetails.phoneNumber === "" || !isPhoneNumberValid) {
                  setIsPhoneNumberValid(false);
                  validationParams.handleSubmit(() =>
                    console.log("phone number required")
                  )();
                } else validationParams.handleSubmit(handleOnClickSubmit)();
                console.log(validationParams.errors);
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Tooltip>
          <Tooltip title="Click Here To Cancel">
            <Button onClick={handleOnBackButtonClick} variant="outlined">
              Cancel
            </Button>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
};

export default PatientForm;
