import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../../pages/account/login";
import CreateRequest from "../../pages/create-request/create-request";
import { AppRoutes } from "../../utility/auth-guards/app-routes";
import { AppRoutings } from "../../utility/enums/app-routings";
import { RouterType } from "../../utility/enums/router-type";
import LayoutType1 from "./layout-type-1";
import LayoutType2 from "./layout-type2";
import ResetPassword from "../../pages/account/reset-password";
import CreateYourAccount from "../../pages/account/create-your-account";
import { UnProtectedRoute } from "../../utility/auth-guards/unprotected-route";
import { ProtectedRoute } from "../../utility/auth-guards/protected-route";
import ReviewAgreement from "../../pages/create-request/review-agreement";
import { PageNotFound } from "./page-not-found";

export const RouterConfig: React.FC = () => {
  return (
    <Routes>
      <Route
        path={AppRoutings.Root}
        element={<UnProtectedRoute element={<LoginPage />} />}
      />
      <Route
        path={AppRoutings.ResetPassword}
        element={<UnProtectedRoute element={<ResetPassword />} />}
      />
      {/* <Route
        path={AppRoutings.CreateAccount}
        element={<UnProtectedRoute element={<CreateYourAccount />} />}
      /> */}
      <Route path="/" element={<LayoutType1 />}>
        <Route
          path={AppRoutings.ReviewAgreement}
          element={<ReviewAgreement />}
        />
      </Route>
      <Route
        path={AppRoutings.CreateAccount}
        element={<CreateYourAccount />}
      ></Route>
      <Route path="/" element={<LayoutType1 />}>
        {AppRoutes.filter(
          (route) => route.routeType === RouterType.unprotectedRoute
        ).map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={<UnProtectedRoute element={<item.element />} />}
            />
          );
        })}
      </Route>
      <Route path="/" element={<LayoutType2 />}>
        {AppRoutes.filter(
          (route) => route.routeType === RouterType.protectedRoute
        ).map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={<ProtectedRoute element={<item.element />} />}
            />
          );
        })}
      </Route>
      <Route path={AppRoutings.NotFound} element={<PageNotFound />} />
      {/* <Route path="/" element={<LayoutType1 />}>
        <Route
          path={AppRoutings.CreateRequest}
          element={<UnProtectedRoute element={<CreateRequest />} />}
        />
      </Route> */}
    </Routes>
  );
};
