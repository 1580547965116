import React, { useContext } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { ArrowBack, DownloadAllIcon } from "../../assests/images";
import { IDocumentInfo } from "../../utility/interfaces/common/document-info";
import myCustomTheme from "../../app.theme";
import { UploadDocument } from "./upload-document";
import { WarningModal } from "./warning-modal";
import { DocumentList } from "./document-list";
import {
  createCommonAPICall,
  hideLoader,
  showLoader,
} from "../../utility/helpers";
import dashboardService from "../../services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import moment from "moment";

interface EnhancedTableToolbarProps {
  selectedDocuments: readonly string[];
  handleOnClickDownloadDocument: any;
}

type ComponentProps = {
  documentList: IDocumentInfo[];
  handleOnClickBackCancel: any;
  selectedFiles: string[];
  onSelectNewFiles: any;
  patientName: string;
  confirmationNumber: string;
  requestId: string;
};

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { selectedDocuments, handleOnClickDownloadDocument } = props;

  return (
    <Box>
      <Button
        variant="outlined"
        disableElevation
        disableRipple
        disableFocusRipple
        disabled={selectedDocuments.length > 0 ? false : true}
        sx={{
          minWidth: "111px",
          [myCustomTheme.breakpoints.down("sm")]: {
            minWidth: "0",
          },
        }}
        onClick={() => handleOnClickDownloadDocument(selectedDocuments, true)}
      >
        <span>Download all</span>
        <img src={DownloadAllIcon} alt="download" />
      </Button>
    </Box>
  );
}

export function ViewDocuments(props: ComponentProps) {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // props
  const {
    documentList,
    handleOnClickBackCancel,
    selectedFiles,
    onSelectNewFiles,
    patientName,
    confirmationNumber,
    requestId,
  } = props;

  // useStates
  const [selectedDocuments, setSelectedDocuments] = React.useState<
    readonly string[]
  >([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isSendDocumentModalOpen, setIsSendDocumentModalOpen] =
    React.useState(false);
  const [documentsNeedToDeleteOrSend, setDocumentsNeedToDeleteOrSend] =
    React.useState<readonly string[]>([]);

  const handleOnClickDeleteButton = () => {
    //TODO: Delete document logic will here
    console.log(documentsNeedToDeleteOrSend);
  };
  const handleOnClickSendEmailButton = () => {
    //TODO: Send emails logic will here
    console.log(documentsNeedToDeleteOrSend);
  };

  const handleOnClickDownloadDocument = async (
    ids?: string[] | null,
    isDownloadMultiple?: boolean
  ) => {
    //TODO: Download logic will here
    console.log(ids);
    const requestBody = {
      requestWiseFileId: ids!.toString(),
      requestId: requestId,
    };
    if (!isDownloadMultiple) {
      const data = await createCommonAPICall({
        requestBody,
        apiService: dashboardService.downloadFiles,
        showErrorMessage: true,
        showSuccessMessage: false,
        setSuccessErrorContext,
      });
      if (data.data.length == 1) {
        let link = document.createElement("a");
        link.href = data.data[0].filePath;
        link.download = data.data[0].fileName;
        document.getElementById("downloadBtn")?.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    } else if (isDownloadMultiple) {
      showLoader();
      dashboardService
        .downloadMultipleFiles(requestBody)
        .then((data) => {
          let link = document.createElement("a");
          link.href = data;
          link.download = `Zip_${moment(new Date()).format(
            "MMDDyyyyhhmmss"
          )}.zip`;
          document.getElementById("downloadBtn")?.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          setSelectedDocuments([]);
          hideLoader();
        })
        .catch((err) => {
          hideLoader();
          console.error(err);
        });
    }
  };

  const handleOnSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected = documentList.map((n) =>
        n.requestWiseFileID.toString()
      );
      setSelectedDocuments(newSelected);
      return;
    }
    setSelectedDocuments([]);
  };

  const handleOnSelectDocument = (doc: number) => {
    const selectedIndex = selectedDocuments.indexOf(doc.toString());
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDocuments, doc.toString());
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDocuments.slice(1));
    } else if (selectedIndex === selectedDocuments.length - 1) {
      newSelected = newSelected.concat(selectedDocuments.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDocuments.slice(0, selectedIndex),
        selectedDocuments.slice(selectedIndex + 1)
      );
    }
    setSelectedDocuments(newSelected);
  };

  return (
    <>
      <main className="main-content">
        <div
          className="overlay"
          onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
        ></div>
        <Box className="request-container-box">
          <div className="request-header">
            <Typography variant="h2">Documents</Typography>
            <Button variant="outlined" onClick={handleOnClickBackCancel}>
              <img src={ArrowBack} alt="arrow" />
              Back
            </Button>
          </div>
          <Box className="request-box">
            <div className="confirm-number uploaded-data">
              <label>Patient Name</label>
              <Typography variant="h4">
                {patientName}{" "}
                <span>&nbsp;&#40;{`${confirmationNumber}`}&#41;</span>
              </Typography>
            </div>
            <Typography variant="body1" className="instruction">
              Check here for any files that you or the doctors of your
              subsequents requestors have attached for you to review.
            </Typography>
            <UploadDocument
              onSelectNewFiles={onSelectNewFiles}
              isMultiple={true}
              accept="*"
              fileName={selectedFiles.toString()}
            />
            <div className="attachment">
              <Typography variant="h4">Documents</Typography>
              <Box className="selected-action-btn">
                <EnhancedTableToolbar
                  selectedDocuments={selectedDocuments}
                  handleOnClickDownloadDocument={handleOnClickDownloadDocument}
                />
              </Box>
            </div>
            <DocumentList
              selectedDocuments={selectedDocuments}
              documentList={documentList}
              handleOnSelectAllClick={handleOnSelectAllClick}
              handleOnSelectDocument={handleOnSelectDocument}
              handleOnClickDownloadDocument={handleOnClickDownloadDocument}
            />
          </Box>
        </Box>
      </main>
      {/* Page Modals */}
      {/* Delete warning*/}
      <WarningModal
        isModalOpen={isDeleteModalOpen}
        handleOnClickCloseModal={() => {
          setDocumentsNeedToDeleteOrSend([]);
          setIsDeleteModalOpen(false);
        }}
        title="Delete"
        warningMessage="Are you sure, want to delete selected document(s)?"
        okButtonText="Delete"
        closeButtonText="Cancel"
        handleOnClickOk={handleOnClickDeleteButton}
      />
      {/* Send email warning*/}
      <WarningModal
        isModalOpen={isSendDocumentModalOpen}
        handleOnClickCloseModal={() => {
          setDocumentsNeedToDeleteOrSend([]);
          setIsSendDocumentModalOpen(false);
        }}
        title="Send"
        warningMessage="Are you sure, want to send selected document(s)?"
        okButtonText="Send"
        closeButtonText="Cancel"
        handleOnClickOk={handleOnClickSendEmailButton}
      />
    </>
  );
}
