import React from "react";
// import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import myCustomTheme from "./app.theme";
import { ToastContainer } from "react-toastify";
import { RouterConfig } from "./components/common/router-config";
import { SuccessErrorModalProvider } from "./contexts/success-error-context";
import { UserProvider } from "./contexts/user-context";
import { Loader, SuccessErrorModal } from "./components";
import { ChatCountProvider } from "./contexts/chat-count-context";

function App() {
  return (
    <>
      <SuccessErrorModalProvider>
        <UserProvider>
          <ChatCountProvider>
            <ThemeProvider theme={myCustomTheme}>
              <SuccessErrorModal />
              <React.Suspense fallback={<Loader />}>
                <BrowserRouter>
                  <RouterConfig />
                  <ToastContainer />
                </BrowserRouter>
              </React.Suspense>
            </ThemeProvider>
          </ChatCountProvider>
        </UserProvider>
      </SuccessErrorModalProvider>
    </>
  );
}

export default App;
