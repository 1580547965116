import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { CloseWhiteIcon } from "../../assests/images";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { commonModalStyle } from "../../utility/helpers";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";

type ComponentProps = {
  responsiblePartyModalOpen: boolean;
  handleResponsiblePartyModalClose: any;
  onSelectResponsibleParty: any;
  isConcierge: boolean;
};

export function SelectResponsiblePartyModal(props: ComponentProps) {
  // Usestates
  const [responsibleParty, setResponsibleParty] = useState("P");

  // Extract Props
  const {
    responsiblePartyModalOpen,
    handleResponsiblePartyModalClose,
    isConcierge,
    onSelectResponsibleParty,
  } = props;

  // Yup resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm();

  // Events and functions
  const handleOnClickConfirm = async () => {
    setResponsibleParty(responsibleParty);
    handleResponsiblePartyModalClose();
  };

  // useEffects
  useEffect(() => {
    reset();
    clearErrors();
    setResponsibleParty("P");
  }, [responsiblePartyModalOpen]);

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponsibleParty(event.target.value);
    onSelectResponsibleParty(event.target.value);
  };

  return (
    <>
      <Modal
        open={responsiblePartyModalOpen}
        onClose={handleResponsiblePartyModalClose}
        className="send-modal"
      >
        <Box sx={commonModalStyle}>
          <Box className="modal-header">
            <Typography variant="h5">
              Who will be the responsible party?
            </Typography>
            <IconButton onClick={handleResponsiblePartyModalClose}>
              <img src={CloseWhiteIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="M"
                  name="radio-buttons-group"
                  value={responsibleParty}
                  onChange={onRadioChange}
                >
                  <FormControlLabel
                    value="P"
                    control={<Radio />}
                    label="Patient"
                  />
                  {!isConcierge && (
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label="You"
                    />
                  )}

                  <FormControlLabel
                    value="O"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="modal-footer">
              <Tooltip title="Confirm">
                <Button
                  variant="contained"
                  onClick={handleSubmit(handleOnClickConfirm)}
                >
                  Select
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  variant="outlined"
                  onClick={handleResponsiblePartyModalClose}
                >
                  Cancel
                </Button>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
