import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "../../assests/images";
import PatientDetails from "../../components/dashboard/patient-details";
import ProfileForm from "../../components/profile/profile-form";

const Profile: React.FC = () => {
  // state value
  const { state } = window.history;

  // Page level variables
  const navigate = useNavigate();

  // Handled events and functions
  const handleOnBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <main className="main-content">
      <div
        className="overlay"
        onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
      ></div>
      <Box className="request-container-box">
        <div className="request-header">
          <Typography variant="h2">User Profile</Typography>
          <Button onClick={handleOnBackButtonClick} variant="outlined">
            <img src={ArrowBack} alt="arrow" />
            Back
          </Button>
        </div>
        <Box>
          <Box className="request-box">
            <ProfileForm />
          </Box>
        </Box>
      </Box>
    </main>
  );
};

export default Profile;
