import { Outlet } from "react-router-dom";
import Footer from "./footer";
import HeaderType2 from "./header-type-2";

const LayoutType2 = () => {
  return (
    <>
      <HeaderType2 />
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutType2;
