import { Divider, Tooltip } from "@mui/material";
import React from "react";
import { FooterLogo, FooterLogoMobile } from "../../assests/images";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import { TermsAndConditions } from "../../assests/pdfs";

const Footer: React.FC = () => {
  // page level variables
  const navigate = useNavigate();

  return (
    <footer>
      <Tooltip title="Doctors To You">
        <a
          className="footer-logo"
          onClick={() => navigate(AppRoutings.Dashboard)}
        >
          <img src={FooterLogo} alt="Doctors To You" className="desktop" />
          <img src={FooterLogoMobile} alt="Doctors To You" className="mobile" />
        </a>
      </Tooltip>
      <div className="footer-links">
        <Tooltip title="Terms of Conditions">
          <a href={TermsAndConditions}>Terms of Conditions</a>
        </Tooltip>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Tooltip title="Privacy Policy">
          <a href="https://doctorstoyou.com/privacy-policy/">Privacy Policy</a>
        </Tooltip>
      </div>
    </footer>
  );
};

export default Footer;
