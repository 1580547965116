import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  LoginBanner,
  EyeOpen,
  EyeClose,
  Logo,
  AccountIcon,
} from "../../assests/images";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authService from "../../services/auth-service";
import {
  openSucessErrorModal,
  createCommonAPICall,
} from "../../utility/helpers";
import { createAccountSchema } from "../../utility/validations";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import { ICreateAccountForm, IHttpsResponse } from "../../utility/interfaces";
import { LightDarkButton, AccountFooter } from "../../components";
import tokenManager from "../../utility/auth-guards/token-manager";

const CreateYourAccount: React.FC = () => {
  // get query parameters
  const { email, caseId } = useParams();

  // useStates
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [formValues, setFormValues] = useState<ICreateAccountForm>({
    email: email || "",
    confirmPassword: "",
    password: "",
  });

  // Page level local variables
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
  const navigate = useNavigate();

  // Handled events and functions
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };
  const handleOnChangeFormValue = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleOnClickCreate = async () => {
    console.log(formValues);
    const requestBody = {
      requestId: caseId ? caseId : "",
      email: formValues.email,
      password: formValues.password,
    };
    const data = await createCommonAPICall({
      requestBody,
      apiService: authService.createAccount,
      showErrorMessage: true,
      showSuccessMessage: true,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      navigate(AppRoutings.Root, {
        state: {
          isLogin: true,
        },
      });
    }
  };

  // Yup resolver
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createAccountSchema),
  });
  useEffect(() => {
    console.log(caseId);
    tokenManager.clearCookiesAndLocalStorage();
  }, []);

  return (
    <>
      <section className="login-wrapper">
        <Grid container className="login-container">
          {/* <Hidden mdDown> */}
            {/* <Grid item xs={12} md={6} className="login-banner">
              <img src={LoginBanner} alt="Banner" />
            </Grid> */}
          {/* </Hidden> */}
          <Grid item xs={12} className="login-content-container">
            <LightDarkButton />
            <div className="login-content">
              <Tooltip title="Doctors To You">
                <a href="/" className="logo">
                  <img src={Logo} alt="Doctors To You" />
                </a>
              </Tooltip>
              <div className="login-content-form">
                <Typography variant="h1">Create Account</Typography>
                <form onSubmit={handleSubmit(handleOnClickCreate)}>
                  <div
                    className="login-form-control"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="username">Email</InputLabel>
                      <OutlinedInput
                        id="email"
                        {...register("email")}
                        autoFocus
                        error={errors?.email?.message ? true : false}
                        value={formValues.email}
                        onChange={handleOnChangeFormValue}
                        className="with-icon"
                        endAdornment={
                          <InputAdornment tabIndex={-1} position="end">
                            <IconButton tabIndex={-1} edge="end">
                              <img
                                tabIndex={-1}
                                src={AccountIcon}
                                alt="account"
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Email"
                      />
                      <FormHelperText error id="component-error-text">
                        <>{errors?.email?.message}</>
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        {...register("password")}
                        id="password"
                        error={errors?.password?.message ? true : false}
                        className="with-icon"
                        name="password"
                        onChange={handleOnChangeFormValue}
                        tabIndex={1}
                        type={showPassword ? "text" : "password"}
                        value={formValues.password}
                        endAdornment={
                          <Tooltip title={showPassword ? "Hide" : "Show"}>
                            <InputAdornment position="end" tabIndex={-1}>
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disableFocusRipple
                                disableRipple
                                tabIndex={-1}
                              >
                                {showPassword ? (
                                  <img
                                    tabIndex={-1}
                                    src={EyeClose}
                                    alt="show"
                                  />
                                ) : (
                                  <img tabIndex={-1} src={EyeOpen} alt="hide" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        }
                        label="Password"
                      />
                      <FormHelperText error id="component-error-text">
                        <>{errors?.password?.message}</>
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="confirmPassword">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        {...register("confirmPassword")}
                        id="confirmPassword"
                        error={errors?.confirmPassword?.message ? true : false}
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        className="with-icon"
                        tabIndex={2}
                        value={formValues.confirmPassword}
                        onChange={handleOnChangeFormValue}
                        endAdornment={
                          <Tooltip
                            title={showConfirmPassword ? "Hide" : "Show"}
                          >
                            <InputAdornment position="end" tabIndex={-1}>
                              <IconButton
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disableFocusRipple
                                disableRipple
                                tabIndex={-1}
                              >
                                {showConfirmPassword ? (
                                  <img
                                    tabIndex={-1}
                                    src={EyeClose}
                                    alt="show"
                                  />
                                ) : (
                                  <img tabIndex={-1} src={EyeOpen} alt="hide" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        }
                        label="Confirm Password"
                      />
                      <FormHelperText error id="component-error-text">
                        <>{errors?.confirmPassword?.message}</>
                      </FormHelperText>
                    </FormControl>
                    <Tooltip title="Reset">
                      <Button variant="contained" type="submit">
                        Create
                      </Button>
                    </Tooltip>
                  </div>
                </form>
              </div>
              <AccountFooter />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default CreateYourAccount;
