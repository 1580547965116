import { createContext, useState } from "react";
import tokenManager from "../utility/auth-guards/token-manager";
const ChatCountContext = createContext();
const ChatCountDispatchContext = createContext();

function ChatCountProvider({ children }) {
  const count = tokenManager.getChatCount();
  const [chatCount, setChatCount] = useState(count);
  const handleChangeChatCount = (newChatCount) => {
    tokenManager.setChatCount(newChatCount);
    setChatCount(newChatCount);
  };

  return (
    <ChatCountContext.Provider value={chatCount}>
      <ChatCountDispatchContext.Provider value={handleChangeChatCount}>
        {children}
      </ChatCountDispatchContext.Provider>
    </ChatCountContext.Provider>
  );
}

export { ChatCountContext, ChatCountDispatchContext, ChatCountProvider };
