import { Box, Typography } from "@mui/material";
import { CreateRequestType } from "../../utility/enums/create-request";

const RequestSuccess: React.FC = () => {
  // get state value
  const { state } = window.history;

  let backgroundColor = "#60bc60 !important";
  switch (state?.usr?.requestTypeId) {
    case CreateRequestType.familyOrFriend:
      backgroundColor = "#ee9125 !important";
      break;
    case CreateRequestType.businessPartners:
      backgroundColor = "#e36478 !important";
      break;
    case CreateRequestType.concierge:
      backgroundColor = "#007fc7 !important";
      break;
    case CreateRequestType.patient:
      backgroundColor = "#60bc60 !important";
      break;
    default:
      backgroundColor = "#60bc60 !important";
      break;
  }

  const boxStyle = {
    backgroundColor: backgroundColor,
  };

  return (
    <>
      <main className="main-content" style={{ minHeight: "76vh" }}>
        <div
          className="overlay"
          onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
        ></div>
        <Box className="request-container-box">
          <Box className="request-box" sx={boxStyle}>
            <Typography
              variant="h4"
              sx={{color: "white !important"}}
              dangerouslySetInnerHTML={{ __html: state?.usr?.message }}
            ></Typography>
          </Box>
        </Box>
      </main>
    </>
  );
};

export default RequestSuccess;
