import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowBack, NewIconActive, NewIconDefault } from "../../assests/images";
import BusinessPartnerForm from "../../components/create-request/business-partner-form";
import ConciergeForm from "../../components/create-request/concierge";
import FamilyOrFriendForm from "../../components/create-request/family-friend-form";
import { InformationTab } from "../../components/create-request/information-tab";
import PatientForm from "../../components/create-request/patient-form";
import { CreateRequestType } from "../../utility/enums/create-request";
import { IInformationTab } from "../../utility/interfaces/common/info-tabs";
import {
  IBusinessPartnerDetails,
  IConciergeDetails,
  IFamilyFriendDetails,
  IPatientDetails,
} from "../../utility/interfaces/create-request/create-request-details";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  businessPartnerFormSchema,
  conciergeFormSchema,
  familyFriendFormSchema,
  patientFormSchema,
} from "../../utility/validations/create-request";
import { IValidationParms } from "../../utility/interfaces/common/validation-parms";

const CreateRequest: React.FC = () => {
  // Page level variables
  const navigate = useNavigate();
  const informationTabs: IInformationTab[] = [
    {
      id: 1,
      type: CreateRequestType.patient,
      defaultIcon: NewIconDefault,
      activeIcon: NewIconActive,
      title: "Patient",
      class: "new",
    },
    {
      id: 2,
      type: CreateRequestType.familyOrFriend,
      defaultIcon: NewIconDefault,
      activeIcon: NewIconActive,
      title: "Family/Friend",
      class: "working",
    },
    {
      id: 3,
      type: CreateRequestType.concierge,
      defaultIcon: NewIconDefault,
      activeIcon: NewIconActive,
      title: "Concierge",
      class: "active",
    },
    {
      id: 4,
      type: CreateRequestType.businessPartners,
      defaultIcon: NewIconDefault,
      activeIcon: NewIconActive,
      title: "Business Partners",
      class: "conclude",
    },
  ];
  // useStates
  const [currentSelectedTab, setCurrentSelectedTab] = useState(0);
  const [patientDetails, setPatientDetails] = useState<IPatientDetails>({
    symptoms: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    phoneNumber: "",
    radioClientPhoneType: "M",
    password: "",
    confirmPassword: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    room: "",
    document: "",
    documentName: "",
    clientRegionId: 0,
  });
  const [conciergeDetails, setConciergeDetails] = useState<IConciergeDetails>({
    conciergeEmail: "",
    conciergeFirstName: "",
    conciergeLastName: "",
    conciergePhoneNumber: "",
    hotelPropertyName: "",
    radioRequestorPhoneType: "M",
    patientDetails: {
      symptoms: "",
      firstName: "",
      lastName: "",
      birthDate: "",
      email: "",
      phoneNumber: "",
      radioClientPhoneType: "M",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      room: "",
      document: "",
      documentName: "",
      clientRegionId: 0,
    },
  });
  const [businessDetails, setBusinessDetails] =
    useState<IBusinessPartnerDetails>({
      businessPartnerEmail: "",
      businessPartnerFirstName: "",
      businessPartnerLastName: "",
      businessPartnerPhoneNumber: "",
      businessPropertyName: "",
      radioRequestorPhoneType: "M",
      caseNumber: "",
      patientDetails: {
        symptoms: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        radioClientPhoneType: "M",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        room: "",
        document: "",
        documentName: "",
        clientRegionId: 0,
      },
    });

  const [familyFriendDetails, setFamilyFriendDetails] =
    useState<IFamilyFriendDetails>({
      friendEmail: "",
      friendFirstName: "",
      friendLastName: "",
      friendPhoneNumber: "",
      relationWithPatient: "",
      radioRequestorPhoneType: "M",
      patientDetails: {
        symptoms: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        radioClientPhoneType: "M",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        room: "",
        document: "",
        documentName: "",
        clientRegionId: 0,
      },
    });

  // Handled events and functions
  const handleOnBackButtonClick = () => {
    if (currentSelectedTab === 0) navigate(-1);
    else setCurrentSelectedTab(0);
  };
  const handleTabChange = (tab: IInformationTab) => {
    setCurrentSelectedTab(tab.type);
  };
  const onChangePatientDetails = (name: string, value: any) => {
    setPatientDetails({ ...patientDetails, [name]: value });
  };
  const onChangeFamilyFriendDetails = (name: string, value: any) => {
    setFamilyFriendDetails({ ...familyFriendDetails, [name]: value });
  };
  const onChangeConciergeDetails = (name: string, value: any) => {
    setConciergeDetails({ ...conciergeDetails, [name]: value });
  };
  const onChangeBusinessDetails = (name: string, value: any) => {
    setBusinessDetails({ ...businessDetails, [name]: value });
  };
  const resetAllForms = () => {
    setPatientDetails({
      symptoms: "",
      firstName: "",
      lastName: "",
      birthDate: "",
      email: "",
      phoneNumber: "",
      radioClientPhoneType: "M",
      password: "",
      confirmPassword: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      room: "",
      document: "",
      documentName: "",
      clientRegionId: 0,
    });
    setFamilyFriendDetails({
      friendEmail: "",
      friendFirstName: "",
      friendLastName: "",
      friendPhoneNumber: "",
      relationWithPatient: "",
      radioRequestorPhoneType: "M",
      patientDetails: {
        symptoms: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        radioClientPhoneType: "M",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        room: "",
        document: "",
        documentName: "",
        clientRegionId: 0,
      },
    });
    setConciergeDetails({
      conciergeEmail: "",
      conciergeFirstName: "",
      conciergeLastName: "",
      conciergePhoneNumber: "",
      hotelPropertyName: "",
      radioRequestorPhoneType: "M",
      patientDetails: {
        symptoms: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        radioClientPhoneType: "M",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        room: "",
        document: "",
        documentName: "",
        clientRegionId: 0,
      },
    });
    setBusinessDetails({
      businessPartnerEmail: "",
      businessPartnerFirstName: "",
      businessPartnerLastName: "",
      businessPartnerPhoneNumber: "",
      businessPropertyName: "",
      radioRequestorPhoneType: "M",
      caseNumber: "",
      patientDetails: {
        symptoms: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        email: "",
        phoneNumber: "",
        radioClientPhoneType: "M",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        room: "",
        document: "",
        documentName: "",
        clientRegionId: 0,
      },
    });
    patientFormReset();
    patientFormClearErrors();
    familyFriendFormReset();
    familyFriendFormClearErrors();
    conciergeFormReset();
    conciergeFormClearErrors();
    businessPartnerFormReset();
    businessPartnerFormClearErrors();
  };

  // useEffects
  useEffect(() => {
    resetAllForms();
  }, [currentSelectedTab]);

  // Yup resolver and validation
  const {
    register: patientFormRegister,
    handleSubmit: patientFormHandleSubmit,
    formState: { errors: patientFormErrors },
    reset: patientFormReset,
    clearErrors: patientFormClearErrors,
    setValue: patientFormSetValue,
    getValues: patientFormGetvalues,
    setError: patientFormSetError,
  } = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(patientFormSchema),
  });
  const patientFormValidation: IValidationParms = {
    register: patientFormRegister,
    handleSubmit: patientFormHandleSubmit,
    errors: patientFormErrors,
    reset: patientFormReset,
    clearErrors: patientFormClearErrors,
    setValue: patientFormSetValue,
    getValues: patientFormGetvalues,
    setError: patientFormSetError,
  };
  const {
    register: familyFriendFormRegister,
    handleSubmit: familyFriendFormHandleSubmit,
    formState: { errors: familyFriendFormErrors },
    reset: familyFriendFormReset,
    clearErrors: familyFriendFormClearErrors,
    setValue: familyFriendFormSetValue,
    setError: familyFriendFormSetError
  } = useForm({
    reValidateMode: "onSubmit",
    values: familyFriendDetails,
    resolver: yupResolver(familyFriendFormSchema),
  });
  const familyFriendFormValidation: IValidationParms = {
    register: familyFriendFormRegister,
    handleSubmit: familyFriendFormHandleSubmit,
    errors: familyFriendFormErrors,
    reset: familyFriendFormReset,
    clearErrors: familyFriendFormClearErrors,
    setValue: familyFriendFormSetValue,
    setError: familyFriendFormSetError
  };
  const {
    register: conciergeFormRegister,
    handleSubmit: conciergeFormHandleSubmit,
    formState: { errors: conciergeFormErrors },
    reset: conciergeFormReset,
    clearErrors: conciergeFormClearErrors,
    setValue: conciergeFormSetValue,
  } = useForm({
    reValidateMode: "onSubmit",
    values: conciergeDetails,
    resolver: yupResolver(conciergeFormSchema),
  });
  const conciergeFormValidation: IValidationParms = {
    register: conciergeFormRegister,
    handleSubmit: conciergeFormHandleSubmit,
    errors: conciergeFormErrors,
    reset: conciergeFormReset,
    clearErrors: conciergeFormClearErrors,
    setValue: conciergeFormSetValue,
  };
  const {
    register: businessPartnerFormRegister,
    handleSubmit: businessPartnerFormHandleSubmit,
    formState: { errors: businessPartnerFormErrors },
    reset: businessPartnerFormReset,
    clearErrors: businessPartnerFormClearErrors,
    setValue: businessPartnerFormSetValue,
  } = useForm({
    reValidateMode: "onSubmit",
    values: businessDetails,
    resolver: yupResolver(businessPartnerFormSchema),
  });
  const businessPartnerFormValidation: IValidationParms = {
    register: businessPartnerFormRegister,
    handleSubmit: businessPartnerFormHandleSubmit,
    errors: businessPartnerFormErrors,
    reset: businessPartnerFormReset,
    clearErrors: businessPartnerFormClearErrors,
    setValue: businessPartnerFormSetValue,
  };

  useEffect(() => {
    Object.entries(patientDetails).forEach((child) => {
      patientFormValidation.setValue(child[0] as any, child[1] || "");
    });
  }, [patientDetails]);

  return (
    <>
      <main className="main-content">
        <div
          className="overlay"
          onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
        ></div>

        <Box className="request-container-box">
          <div className="request-header">
            <div></div>
            {currentSelectedTab === 0 && (
              <Typography variant="h1" fontWeight={600}>
                I am a...
              </Typography>
            )}
            <Button onClick={handleOnBackButtonClick} variant="outlined">
              <img src={ArrowBack} alt="arrow" />
              Back
            </Button>
          </div>
          {/* <div className="request-header">
            <Typography variant="h5">I am a...</Typography>
          </div> */}
          {currentSelectedTab === 0 && (
            <InformationTab
              tabs={informationTabs}
              currentTabValue={currentSelectedTab}
              handleTabChange={handleTabChange}
              repeatSequence={4}
            />
          )}
          {currentSelectedTab === CreateRequestType.patient && (
            <>
              <PatientForm
                handleOnBackButtonClick={handleOnBackButtonClick}
                validationParams={patientFormValidation}
                patientDetails={patientDetails}
                onChangePatientDetails={onChangePatientDetails}
              />
            </>
          )}
          {currentSelectedTab === CreateRequestType.familyOrFriend && (
            <FamilyOrFriendForm
              handleOnBackButtonClick={handleOnBackButtonClick}
              validationParams={familyFriendFormValidation}
              details={familyFriendDetails}
              onChangeDetails={onChangeFamilyFriendDetails}
            />
          )}
          {currentSelectedTab === CreateRequestType.concierge && (
            <ConciergeForm
              handleOnBackButtonClick={handleOnBackButtonClick}
              validationParams={conciergeFormValidation}
              details={conciergeDetails}
              onChangeDetails={onChangeConciergeDetails}
            />
          )}
          {currentSelectedTab === CreateRequestType.businessPartners && (
            <BusinessPartnerForm
              handleOnBackButtonClick={handleOnBackButtonClick}
              validationParams={businessPartnerFormValidation}
              details={businessDetails}
              onChangeDetails={onChangeBusinessDetails}
            />
          )}
        </Box>
      </main>
    </>
  );
};

export default CreateRequest;
