import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { CloseWhiteIcon } from "../../assests/images";
import { commonModalStyle } from "../../utility/helpers/common";

type ComponentProps = {
  isModalOpen: boolean;
  handleOnModalClose: any;
  isRequestForMe: boolean;
  onChangeRequestType: any;
  onClickContinue: any;
};

export function CreateRequestModal(props: ComponentProps) {
  // Extract Props
  const {
    isModalOpen,
    handleOnModalClose,
    isRequestForMe,
    onChangeRequestType,
    onClickContinue,
  } = props;

  // useEffects
  useEffect(() => {
    onChangeRequestType(true);
  }, [isModalOpen]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleOnModalClose}
        className="send-modal"
      >
        <Box sx={commonModalStyle}>
          <Box className="modal-header">
            <Typography variant="h5">Create New Request</Typography>
            <IconButton onClick={handleOnModalClose}>
              <img src={CloseWhiteIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <div>
              <Typography variant="h6" className="patient-name">
                Here, I want to create new request for...
              </Typography>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant={isRequestForMe ? "contained" : "outlined"}
                  onClick={() => onChangeRequestType(true)}
                  style={{ margin: "0px 10px 10px 10px" }}
                >
                  Me
                </Button>
                <Button
                  variant={!isRequestForMe ? "contained" : "outlined"}
                  onClick={() => onChangeRequestType(false)}
                  style={{ margin: "0px 10px 10px 10px" }}
                >
                  Some one else
                </Button>
              </div>
            </div>
            <div className="modal-footer">
              <Tooltip title="Continue">
                <Button variant="contained" onClick={onClickContinue}>
                  Continue
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button variant="outlined" onClick={handleOnModalClose}>
                  Cancel
                </Button>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
