import { TextField } from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarDarkIcon } from "../../assests/images";

type ComponentProps = {
  value: string | undefined;
  label: string;
  onChange: any;
  name: string;
  disabled: boolean;
  register?: any;
  error?: boolean;
  message?: string;
};

const CustomDatePicker = (props: ComponentProps) => {
  const { value, onChange, label, name, disabled, error, message, register } =
    props;
  function datePickerIcon() {
    return (
      <>
        <img src={CalendarDarkIcon} alt="calendar" />
      </>
    );
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...(register !== undefined ? register(name) : "")}
          disabled={disabled}
          label={label}
          value={value || null}
          inputFormat="MM/DD/YYYY"
          onChange={(newValue) => {
            onChange(name, newValue);
          }}
          components={{
            OpenPickerIcon: datePickerIcon,
          }}
          renderInput={(params) => {
            console.log(params.inputProps);
            return (
              <TextField
                {...params}
                fullWidth
                id="date"
                className="datepicker"
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                }}
                error={error}
                helperText={message ? message : ""}
              />
            );
          }}
        />
        {/* <DesktopDatePicker
          {...(register !== undefined ? register(name) : "")}
          disabled={disabled}
          label={label}
          value={value || null}
          inputFormat="MM/DD/YYYY"
          onChange={(newValue) => {
            onChange(name, newValue);
          }}
          components={{
            OpenPickerIcon: datePickerIcon,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="date"
              className="datepicker"
              autoComplete="off"
              inputProps={{
                ...params.inputProps,
              }}
              error={error}
              helperText={message ? message : ""}
            />
          )}
        /> */}
      </LocalizationProvider>
    </>
  );
};

export default CustomDatePicker;
