import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountIcon, EyeClose, EyeOpen } from "../../assests/images";
import { ILoginRequest } from "../../utility/interfaces/account/login-request";
import { createCommonAPICall } from "../../utility/helpers";
import authService from "../../services/auth-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";
import { IUserDetails } from "../../utility/interfaces";
import { UserDispatchContext } from "../../contexts/user-context";
import tokenManager from "../../utility/auth-guards/token-manager";
import { AppRoutings } from "../../utility/enums/app-routings";
import { ChatCountDispatchContext } from "../../contexts/chat-count-context";

type ComponentProps = {
  handleClickForgotPassword: any;
  isHaloMember: boolean;
};

export function LoginForm(props: ComponentProps) {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
  const setUserContext = useContext(UserDispatchContext);
  const setChatCount = useContext(ChatCountDispatchContext);

  // get query parameters
  const search = useLocation().search;
  const backUrl = new URLSearchParams(search).get("back");

  // Page level local variables
  const navigate = useNavigate();

  // Extract Props
  const { handleClickForgotPassword, isHaloMember } = props;
  const isLoginFormValidate = () => {
    const errors: ILoginFormError = {
      passwordError: "",
      emailError: "",
    };

    if (formValues.password.trim() === "")
      errors.passwordError = "Password is required.";
    if (formValues.userName.trim() === "")
      errors.emailError = "Email is required.";

    setFormError(errors);

    return !(errors.passwordError !== "" || errors.emailError !== "");
  };

  const handleClickLogin = async () => {
    console.log("Handle on click login");
    if (!isLoginFormValidate()) return;
    const data = await createCommonAPICall({
      requestBody: formValues,
      apiService: authService.patientLogin,
      showSuccessMessage: false,
      showErrorMessage: true,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      console.log(data);
      const userDetails: IUserDetails = {
        email: data.data.email,
        firstName: data.data.firstName,
        isOnCall: data.data.isOnCall,
        lastName: data.data.lastName,
        photo: data.data.photo,
        regionId: data.data.regionId,
        regionName: data.data.regionName,
        signature: data.data.signature,
        encUserName: data.data.encUserName,
        userRoleId: data.data.userRoleId,
        menuPermissions: "",
      };
      tokenManager.setAuthorization(
        data.data.dtySecret,
        data.data.accessToken,
        JSON.stringify(userDetails)
      );
      setUserContext(userDetails);

      setChatCount(data.data.chatCount);

      navigate(backUrl ? backUrl : AppRoutings.Dashboard, { replace: true });
    }
  };

  // page level interfaces
  interface ILoginFormError {
    emailError: string;
    passwordError: string;
  }

  // useStates
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState<ILoginRequest>({
    userName: "",
    password: "",
  });

  const [formError, setFormError] = useState<ILoginFormError>({
    emailError: "",
    passwordError: "",
  });

  // Handled events
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };
  const handleOnChangeFormValue = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Typography variant="h1">
        {isHaloMember ? "Halo Login" : "Login to your account"}
      </Typography>
      <div
        className="login-form-control"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormControl variant="outlined">
          <InputLabel htmlFor="username">Email</InputLabel>
          <OutlinedInput
            error={formError.emailError !== "" ? true : false}
            id="username"
            className="with-icon"
            name="userName"
            onChange={handleOnChangeFormValue}
            autoFocus
            tabIndex={1}
            endAdornment={
              <InputAdornment position="end" tabIndex={-1}>
                <IconButton
                  tabIndex={-1}
                  edge="end"
                  disableFocusRipple
                  disableRipple
                >
                  <img tabIndex={-1} src={AccountIcon} alt="account" />
                </IconButton>
              </InputAdornment>
            }
            label="Username"
          />
          <FormHelperText error id="component-error-text">
            <>{formError.emailError}</>
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            className="with-icon"
            error={formError.passwordError !== "" ? true : false}
            tabIndex={2}
            onChange={handleOnChangeFormValue}
            endAdornment={
              <Tooltip title={showPassword ? "Hide" : "Show"}>
                <InputAdornment position="end" tabIndex={-1}>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disableFocusRipple
                    disableRipple
                    tabIndex={-1}
                  >
                    {showPassword ? (
                      <img tabIndex={-1} src={EyeClose} alt="show" />
                    ) : (
                      <img tabIndex={-1} src={EyeOpen} alt="hide" />
                    )}
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            }
            label="Password"
          />
          <FormHelperText error id="component-error-text">
            <>{formError.passwordError}</>
          </FormHelperText>
        </FormControl>
        <Tooltip title="Log In">
          <Button variant="contained" onClick={handleClickLogin}>
            Log In
          </Button>
        </Tooltip>
        <Tooltip title="Forgot password?">
          <Button onClick={handleClickForgotPassword} className="back-login">
            Forgot password?
          </Button>
        </Tooltip>
      </div>
    </>
  );
}
