import TrackProvider from "pages/dashboard/track-provider";
import CreateYourAccount from "../../pages/account/create-your-account";
import LoginPage from "../../pages/account/login";
import ResetPassword from "../../pages/account/reset-password";
import Chats from "../../pages/chat/chats";
import CreateRequest from "../../pages/create-request/create-request";
import RequestSuccess from "../../pages/create-request/request-success";
import ReviewAgreement from "../../pages/create-request/review-agreement";
import CreateNewRequest from "../../pages/dashboard/create-new-request";
import Dashboard from "../../pages/dashboard/dashboard";
import ViewUploadedDocuments from "../../pages/dashboard/view-documents";
import Profile from "../../pages/profile/profile";
import { AppRoutings } from "../enums/app-routings";
import { RouterType } from "../enums/router-type";

interface IRoute {
  path: string;
  element: any;
  // Notes:
  //  -required if protected route
  //  -connected with menu permission -> menukey
  routeType: number;
  moduleName?: string;
  pageName?: string;
  pageTitle?: string;
  description?: string;
}

export const AppRoutes: IRoute[] = [
  {
    path: AppRoutings.Root,
    element: LoginPage,
    routeType: RouterType.unprotectedRoute,
    moduleName: "Login",
    pageName: "Login",
    pageTitle: "Login",
    description: "Login",
  },
  {
    path: AppRoutings.RequestSuccess,
    element: RequestSuccess,
    routeType: RouterType.unprotectedRoute,
    moduleName: "Request Success",
    pageName: "Request Success",
    pageTitle: "Request Success",
    description: "Request Success",
  },
  {
    path: AppRoutings.ResetPassword,
    element: ResetPassword,
    routeType: RouterType.unprotectedRoute,
    moduleName: "Reset Password",
    pageName: "Reset Password",
    pageTitle: "Reset Password",
    description: "Reset Password",
  },
  {
    path: AppRoutings.CreateAccount,
    element: CreateYourAccount,
    routeType: RouterType.unprotectedRoute,
    moduleName: "Create Account",
    pageName: "Create Account",
    pageTitle: "Create Account",
    description: "Create Account",
  },
  {
    path: AppRoutings.CreateRequest,
    element: CreateRequest,
    routeType: RouterType.unprotectedRoute,
    moduleName: "Create Request",
    pageName: "Create Request",
    pageTitle: "Create Request",
    description: "Create Request",
  },
  {
    path: AppRoutings.Dashboard,
    element: Dashboard,
    routeType: RouterType.protectedRoute,
    moduleName: "Dashboard",
    pageName: "Dashboard",
    pageTitle: "Dashboard",
    description: "Dashboard",
  },
  {
    path: AppRoutings.ViewDocuments,
    element: ViewUploadedDocuments,
    routeType: RouterType.protectedRoute,
    moduleName: "Uploaded Documents",
    pageName: "Uploaded Documents",
    pageTitle: "Uploaded Documents",
    description: "Uploaded Documents",
  },
  {
    path: AppRoutings.CreateNewRequest,
    element: CreateNewRequest,
    routeType: RouterType.protectedRoute,
    moduleName: "Create New Request",
    pageName: "Create New Request",
    pageTitle: "Create New Request",
    description: "Create New Request",
  },
  {
    path: AppRoutings.Chat,
    element: Chats,
    routeType: RouterType.protectedRoute,
    moduleName: "Chat",
    pageName: "Chat",
    pageTitle: "Chat",
    description: "Chat",
  },
  {
    path: AppRoutings.Profile,
    element: Profile,
    routeType: RouterType.protectedRoute,
    moduleName: "Profile",
    pageName: "Profile",
    pageTitle: "Profile",
    description: "Profile",
  },
  {
    path: AppRoutings.ReviewAgreement,
    element: ReviewAgreement,
    routeType: RouterType.unprotectedRoute,
    moduleName: "Review Agreement",
    pageName: "Review Agreement",
    pageTitle: "Review Agreement",
    description: "Review Agreement",
  },
  {
    path: AppRoutings.TrackProvider,
    element: TrackProvider,
    routeType: RouterType.protectedRoute,
    moduleName: "Track Provider",
    pageName: "Track Provider",
    pageTitle: "Track Provider",
    description: "Track Provider",
  },
];
