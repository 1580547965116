import React, { useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { CheckIcon, DownloadIcon, UncheckIcon } from "../../assests/images";
import { IDocumentInfo } from "../../utility/interfaces/common/document-info";
import {
  getComparator,
  getFileIconByType,
  stableSort,
} from "../../utility/helpers/common";
import myCustomTheme from "../../app.theme";
import moment from "moment";

type ComponentProps = {
  selectedDocuments: readonly string[];
  documentList: IDocumentInfo[];
  handleOnSelectAllClick: any;
  handleOnSelectDocument: any;
  handleOnClickDownloadDocument: any;
};
export function DocumentList(props: ComponentProps) {
  // useStates
  type Order = "asc" | "desc";
  const [orderDirection, setOrderDirection] = useState<Order>("asc");

  // Extract Props
  const {
    selectedDocuments,
    documentList,
    handleOnSelectAllClick,
    handleOnSelectDocument,
    handleOnClickDownloadDocument,
  } = props;

  // Page level interfaces
  interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
  }

  const CheckedboxIcon = () => {
    return <img src={CheckIcon} alt="checkbox" />;
  };
  const CheckboxIcon = () => {
    return <img src={UncheckIcon} alt="checkbox" />;
  };
  const handleOnChangeSortDirection = () => {
    const isAsc = orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
  };

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;
    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            sx={{
              minWidth: 72,
              [myCustomTheme.breakpoints.down("sm")]: {
                minWidth: 62,
              },
            }}
            align="center"
          >
            <Checkbox
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedboxIcon />}
              color="primary"
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disableRipple
              disableFocusRipple
              inputProps={{
                "aria-label": "select all files",
              }}
            />
          </TableCell>
          <TableCell sx={{ minWidth: 210 }}></TableCell>
          <TableCell sortDirection={orderDirection} sx={{ minWidth: 160 }}>
            Uploader
          </TableCell>
          <TableCell sortDirection={orderDirection} sx={{ minWidth: 160 }}>
            <TableSortLabel
              active={true}
              direction={orderDirection}
              onClick={handleOnChangeSortDirection}
            >
              Upload Date
              <Box component="span" sx={visuallyHidden}>
                {orderDirection === "desc"
                  ? "sorted descending"
                  : "sorted ascending"}
              </Box>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" sx={{ width: 142 }}>
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <TableContainer className="upload-table-container">
        <Table className="upload-table">
          <EnhancedTableHead
            numSelected={selectedDocuments.length}
            onSelectAllClick={handleOnSelectAllClick}
            rowCount={documentList.length}
          />
          <TableBody>
            {stableSort(
              documentList,
              getComparator(orderDirection, "createdDate")
            ).map((document, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={document.requestWiseFileID}
                  selected={
                    selectedDocuments.indexOf(document.requestWiseFileID.toString()) !== -1
                  }
                >
                  <TableCell padding="checkbox" align="center">
                    <Checkbox
                      onClick={() => handleOnSelectDocument(document.requestWiseFileID)}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedboxIcon />}
                      checked={
                        selectedDocuments.indexOf(document.requestWiseFileID.toString()) !== -1
                      }
                      disableRipple
                      disableFocusRipple
                      inputProps={{
                        "aria-labelledby": `enhanced-table-checkbox-${index}`,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={`enhanced-table-checkbox-${index}`}
                    scope="row"
                    padding="none"
                    className="upload-file-col"
                  >
                    <div className="upload-file">
                      <img src={getFileIconByType(document.mimeType)} alt="file" />
                      <span>{document.fileName}</span>
                    </div>
                  </TableCell>

                  <TableCell>{document.uploader}</TableCell>
                  <TableCell>
                    {moment(document.createdDate).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    <div className="upload-actions">
                      <Tooltip title="Download">
                        <IconButton
                          onClick={() =>
                            handleOnClickDownloadDocument(
                              [document.requestWiseFileID.toString()],
                              false
                            )
                          }
                          disableFocusRipple
                          disableRipple
                        >
                          <img src={DownloadIcon} alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
