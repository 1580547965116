import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CreateRequestModal } from "../../components/dashboard/create-request-modal";
import { DashboardTable } from "../../components/dashboard/table";
import { Constants } from "../../utility/enums/constants";
import { SortOrder } from "../../utility/enums/sort-order";
import { ISearchCriteria } from "../../utility/interfaces/common/search-criteria";
import { IDashboardInfo } from "../../utility/interfaces/dashboard/dashboard-info";
import { createCommonAPICall } from "../../utility/helpers";
import dashboardService from "../../services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "../../contexts/success-error-context";

const Dashboard: React.FC = () => {
  // use context
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // useStates
  const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
    PageIndexId: 0,
    PageSize: Constants.DefaultTablePageSize.valueOf(),
    SortOrder: SortOrder.descending,
    SortBy: "createdDate",
    SearchBy: "",
  });
  const [dashboardPageInfo, setDashboardPageInfo] =
    useState<IDashboardInfo | null>({
      requests: [],
      totalRecords: 4,
    });

  // Handled events and functions
  const handleOnSearchCriteriaChange = (newSearchCriteria: ISearchCriteria) => {
    setSearchCriteria(newSearchCriteria);
  };

  const getDashboardInfo = async () => {
    console.log("Search criteria: ", searchCriteria);
    const data = await createCommonAPICall({
      requestBody: searchCriteria,
      apiService: dashboardService.getUserDashboardGrid,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    });

    if(data && data.isSuccessfull){
      console.log(data);
      setDashboardPageInfo(data.data);
    }
  };

  // use effect
  useEffect(() => {
    getDashboardInfo();
  }, [searchCriteria]);

  return (
    <>
      <Box>
        <div
          className="overlay"
          onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
        ></div>
        <div className="chatbox-overlay"></div>
        <main className="main-content">
          <div
            className="overlay"
            onClick={() => document.body.classList.toggle("sidebar-toggle")}
          ></div>
          <Box className="tab-item-header">
            <Typography variant="h2">Medical History</Typography>
          </Box>
          <DashboardTable
            searchCriteria={searchCriteria}
            dashboardPageInfo={dashboardPageInfo}
            handleOnSearchCriteriaChange={handleOnSearchCriteriaChange}
          />
        </main>
      </Box>
    </>
  );
};

export default Dashboard;
