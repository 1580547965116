import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { ArrowBackWhite, PasswordReset } from "../../assests/images";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 556,
  borderRadius: "10px",
  bgcolor: "background.paper",
  padding: "76px 74px 70px",
  textAlign: "center",
  "@media (max-width:599px)": {
    width: "calc(100% - 30px)",
    padding: "30px",
  },
};

type ComponentProps = {
  handleClickBackToLogin: any;
  isResetPwdopen: any;
  handleOutsideClick: any;
};

export function ResetPasswordModal(props: ComponentProps) {
  // Extract Props
  const { handleClickBackToLogin, handleOutsideClick, isResetPwdopen } = props;

  return (
    <>
      <Modal
        open={isResetPwdopen}
        onClose={handleOutsideClick}
        className="reset-modal"
      >
        <Box sx={style}>
          <img src={PasswordReset} alt="Done" className="reset-img" />
          <Typography variant="h2">Forgot Password Confirmation</Typography>
          <Typography variant="body1">
            We've sent an email to you to reset your password. Please follow the
            instructions in it.
          </Typography>
          <Tooltip title="Back to Login">
            <Button
              focusRipple
              tabIndex={0}
              onClick={handleClickBackToLogin}
              variant="contained"
            >
              <img src={ArrowBackWhite} alt="arrow" />
              Back to Login
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    </>
  );
}
