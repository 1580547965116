import { Menu, MenuItem } from "@mui/material";
import React from "react";

type ComponentProps = {
	isRight: boolean;
	chatMenu: any;
	chatMenuOpen: any;
	handleChatMenuClose: any;
};
export function ChatMenu(props: ComponentProps) {
	const { isRight, chatMenu, chatMenuOpen, handleChatMenuClose } = props;
	return (
		<>
			<Menu
				id={isRight ? "chat-menu-right" : "chat-menu"}
				anchorEl={chatMenu}
				open={chatMenuOpen}
				onClose={handleChatMenuClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: isRight ? "right" : "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: !isRight ? "right" : "left",
				}}
				className="chat-menu"
			>
				<MenuItem onClick={handleChatMenuClose}>Copy</MenuItem>
				<MenuItem onClick={handleChatMenuClose}>Select</MenuItem>
				<MenuItem onClick={handleChatMenuClose}>Delete</MenuItem>
			</Menu>
		</>
	);
}
