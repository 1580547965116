import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseWhiteIcon } from "../../assests/images";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { commonModalStyle } from "../../utility/helpers";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";

type ComponentProps = {
  isAudioVideoModalOpen: boolean;
  handleClickAudioVideoClose: any;
  handleSendAudioVideo: any;
};

export function AudioVideoRecordModal(props: ComponentProps) {
  // useStates
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const [recordedVideo, setRecordedVideo] = useState<Blob | null>(null);
  const [liveVideoStream, setLiveVideoStream] = useState<MediaStream | null>(
    null
  );

  // Component level variable
  let audioChunks: Blob[] = [];

  // Extracting props
  const {
    isAudioVideoModalOpen,
    handleClickAudioVideoClose,
    handleSendAudioVideo,
  } = props;

  // Event Handlers
  const handleClickVideoRecord = () => {
    if (!isRecordingStarted) {
      setRecordedAudio(null);
      setRecordedVideo(null);
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          setLiveVideoStream(stream);
          let mediaRecorder = new MediaRecorder(stream, {
            mimeType: "video/webm",
          });
          mediaRecorder.addEventListener(
            "dataavailable",
            handleOnDataAvailableVideo
          );
          mediaRecorder.start();
          setIsRecordingStarted(true);
          setMediaRecorder(mediaRecorder);
        })
        .catch((e) => {
          toast.error("Enable to open camera!");
        });
    }
  };

  const handleClickAudioRecord = () => {
    if (!isRecordingStarted) {
      setRecordedAudio(null);
      setRecordedVideo(null);
      audioChunks = [];
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          let mediaRecorder = new MediaRecorder(stream);
          mediaRecorder.addEventListener(
            "dataavailable",
            handleOnDataAvailableAudio
          );
          mediaRecorder.start();
          setIsRecordingStarted(true);
          setMediaRecorder(mediaRecorder);
        })
        .catch((e) => {
          toast.error("Enable to access microphone!");
        });
    }
  };

  const handleOnDataAvailableAudio = (event: BlobEvent) => {
    audioChunks.push(event.data);
    const audioBlob = new Blob(audioChunks, { type: event.data.type });
    setRecordedAudio(audioBlob);
  };

  const handleOnDataAvailableVideo = (event: BlobEvent) => {
    setRecordedVideo(event.data);
  };

  const handleClickAudioRecordStop = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsRecordingStarted(false);
  };

  const handleDownloadRecording = () => {
    let link = document.createElement("a");
    if (recordedAudio) {
      link.href = URL.createObjectURL(recordedAudio);
      link.download = `recorded-audio-${moment(new Date()).format(
        "YYYYMMDDhhmmss"
      )}.mp3`;
    } else if (recordedVideo) {
      link.href = URL.createObjectURL(recordedVideo);
      link.download = `recorded-video-${moment(new Date()).format(
        "YYYYMMDDhhmmss"
      )}.mp4`;
    }
    document.getElementById("downloadBtn")?.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const handleClickSendButton = () => {
    if (recordedAudio) {
      handleSendAudioVideo(recordedAudio);
    } else if (recordedVideo) {
      handleSendAudioVideo(recordedVideo);
    }
    setRecordedAudio(null);
    setRecordedVideo(null);
    handleClickAudioVideoClose();
  };

  const handleClickClose = () => {
    setRecordedAudio(null);
    setRecordedVideo(null);
    handleClickAudioVideoClose();
  };

  return (
    <>
      <Modal
        open={isAudioVideoModalOpen}
        onClose={handleClickAudioVideoClose}
        className="send-modal"
      >
        <Box sx={commonModalStyle}>
          <Box className="modal-header">
            <Typography variant="h5">Send Audio/Video</Typography>
            <IconButton onClick={handleClickClose}>
              <img src={CloseWhiteIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body align-center">
            <div id="downloadBtn"></div>
            <div className="audio-video-container">
              {isRecordingStarted && (
                <Typography variant="h5">Recording started...</Typography>
              )}
              {recordedAudio && !isRecordingStarted && (
                <audio
                  controls
                  src={URL.createObjectURL(recordedAudio)}
                ></audio>
              )}
              {liveVideoStream && isRecordingStarted && (
                <video
                  autoPlay
                  muted
                  playsInline
                  ref={(ref) => {
                    if (ref) ref.srcObject = liveVideoStream;
                  }}
                  style={{ width: "-webkit-fill-available" }}
                ></video>
              )}
              {recordedVideo && !isRecordingStarted && (
                <video
                  controls
                  src={URL.createObjectURL(recordedVideo)}
                  style={{ width: "-webkit-fill-available" }}
                ></video>
              )}
            </div>
            <div className="record-btn-group">
              <Tooltip title={"Record Video"}>
                <Button
                  variant="contained"
                  onClick={handleClickVideoRecord}
                  disabled={isRecordingStarted}
                  sx={{ marginBottom: "10px" }}
                >
                  {/* Record Video */}
                  <VideocamIcon></VideocamIcon>
                </Button>
              </Tooltip>
              <Tooltip title={"Record Audio"}>
                <Button
                  variant="contained"
                  onClick={handleClickAudioRecord}
                  disabled={isRecordingStarted}
                  sx={{ marginBottom: "10px" }}
                >
                  {/* Record Audio */}
                  <MicIcon />
                </Button>
              </Tooltip>
              <Tooltip title={"Stop Recording"}>
                <Button
                  variant="contained"
                  disabled={!isRecordingStarted}
                  onClick={handleClickAudioRecordStop}
                  sx={{ marginBottom: "10px" }}
                >
                  {/* Stop */}
                  <StopIcon />
                </Button>
              </Tooltip>

              <Tooltip title={"Download"}>
                <Button
                  variant="contained"
                  disabled={!recordedAudio && !recordedVideo}
                  onClick={handleDownloadRecording}
                  sx={{ marginBottom: "10px" }}
                >
                  {/* Download */}
                  <DownloadIcon />
                </Button>
              </Tooltip>
            </div>
            <div className="modal-footer">
              <Tooltip title="Send">
                <Button
                  variant="contained"
                  disabled={!recordedAudio && !recordedVideo}
                  onClick={handleClickSendButton}
                >
                  Send
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button variant="outlined" onClick={handleClickClose}>
                  Cancel
                </Button>
              </Tooltip>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
