import { Tooltip } from "@mui/material";
import { Logo } from "../../assests/images";

export function AccountHeader() {
  return (
    <>
      <Tooltip title="Doctors To You">
        <a href="/" className="logo">
          <img src={Logo} alt="Doctors To You" />
        </a>
      </Tooltip>
    </>
  );
}
