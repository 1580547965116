export enum RequestStatus {
	Unassigned = 1,
	Accepted = 2,
	Cancelled = 3,
	Reserving = 4,
	MDEnRoute = 5,
	MDOnSite = 6,
	FollowUp = 7,
	Closed = 8,
	Locked = 9,
	Rejected = 10,
	Consult = 11,
	Clear = 12,
	CancelledByProvider = 13,
	CCUploadedByClient = 14,
	CCApprovedByAdmin = 15,
	NotReserved = 16,
	Conclude = 17,
	DeclinedByTransaction = 18,
	SettleOffline = 19,
	Deleted = 20,
	CloseCancelled = 21,
}