export enum AppRoutings {
  Root = "/",
  NotFound = "/*",
  RequestSuccess = "/request-success",
  ResetPassword = "/account/reset-password",
  CreateAccount = "/account/create-account/:email/:caseId",
  AccessDenied = "/access-denied",
  CreateRequest = "/Request",
  Dashboard = "/dashboard",
  ViewDocuments = "/dashboard/document/:requestId",
  CreateNewRequest = "/dashboard/create-new-request",
  Chat = "/chat",
  Profile = "/profile",
  ReviewAgreement = "/Request/ReviewAgreement/:data",
  TrackProvider = "/dashboard/track-provider/:requestId",
}
