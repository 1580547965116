import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "assests/images";
import { GoogleMap } from "components/dashboard/google-map";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import dashboardService from "services/dashboard-service";
import { Constants } from "utility/enums/constants";
import { createCommonAPICall } from "utility/helpers";
import { ITrackProviderInfo } from "utility/interfaces";

const TrackProvider: React.FC = () => {
  // Context variables
  const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);

  // get query parameters
  const { requestId } = useParams();

  // Page level local variable
  const navigate = useNavigate();

  // State variables
  const [trackProviderInfo, setTrackProvierInfo] =
    useState<ITrackProviderInfo | null>(null);

  const [locationHubConnection, setLocationHubConnection] =
    useState<null | HubConnection>(null);

  // Handle events
  const handleOnClickBackCancel = () => navigate(-1);

  const handleGetTrackProviderData = async () => {
    const requestBody = {
      id: requestId,
    };
    console.log(requestBody);
    const data = await createCommonAPICall({
      requestBody,
      apiService: dashboardService.getCurrentProviderLocation,
      showErrorMessage: true,
      showSuccessMessage: false,
      setSuccessErrorContext,
    });

    if (data && data.isSuccessfull) {
      console.log(data);
      setTrackProvierInfo(data.data);
    }
  };

  const handleGetLatestProviderTrack = () => {
    if (locationHubConnection) {
      locationHubConnection.send(
        "GetLatestProviderLocation",
        trackProviderInfo?.requestId ?? 0
      );
    }
  };

  const handleStartInterval = () => {
    return setInterval(handleGetLatestProviderTrack, 5000);
  };

  const handleConnectHub = () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(Constants.LocationHubUrl)
      .withAutomaticReconnect()
      .build();

    setLocationHubConnection(newConnection);
  };

  // Use effects
  useEffect(() => {
    if (requestId) {
      handleGetTrackProviderData();
      handleConnectHub();

      let interval = handleStartInterval();

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    if (locationHubConnection) {
      locationHubConnection
        .start()
        .then((result) => {
          console.log("Location hub connected");
          locationHubConnection.on("NotifyUser", (data) => {
            setTrackProvierInfo(data);
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [locationHubConnection]);

  return (
    <>
      <Box>
        <main className="main-content">
          <div
            className="overlay"
            onClick={(e) => document.body.classList.toggle("sidebar-toggle")}
          ></div>
          <Box className="request-container-box">
            <div className="request-header">
              <Typography variant="h2">Track Provider</Typography>
              <Button variant="outlined" onClick={handleOnClickBackCancel}>
                <img src={ArrowBack} alt="arrow" />
                Back
              </Button>
            </div>
            <Box className="request-box">
              {trackProviderInfo && (
                <GoogleMap trackProviderInfo={trackProviderInfo} />
              )}
            </Box>
          </Box>
        </main>
      </Box>
    </>
  );
};

export default TrackProvider;
