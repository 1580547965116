import { AxiosResponse } from "axios";
import { IHttpsResponse, ISearchCriteria } from "../utility/interfaces";
import { ProtectedEndPoints, UnProtectedEndPoints } from "./api-end-points";
import httpClient from "./base-service";

const getUserDashboardGrid = async (
  request: ISearchCriteria
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.GetUserDashboardGrid,
    request
  );

const getViewUploadsInfo = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.GetViewUploadsInfo,
    request
  );

const uploadFiles = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(ProtectedEndPoints.UploadFiles, request);

const downloadFiles = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.DownloadFiles,
    request
  );

const downloadMultipleFiles = async (request: any) => {
  var data = await httpClient.post<Blob>(
    ProtectedEndPoints.DownloadMultipleFiles,
    request,
    {
      responseType: "blob",
    }
  );
  return URL.createObjectURL(data.data);
};

const getCreateCareProfile = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.GetCreateCareProfile,
    request
  );

const getCreateRequestInfoForMe = async (): Promise<
  AxiosResponse<IHttpsResponse<any>>
> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.GetCreateRequestInfoForMe
  );

const updateCareProfile = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.UpdateCareProfile,
    request
  );

const uploadAudioVideoForChat = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.uploadAudioVideoForChat,
    request
  );

const getLoggedInUserId = async (): Promise<
  AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(ProtectedEndPoints.GetLoggedInUserId);

const getAllZipCodes = async (): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.get<IHttpsResponse<any>>(UnProtectedEndPoints.GetAllZipCodes);

const getCurrentProviderLocation = async (
  request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
  httpClient.post<IHttpsResponse<any>>(
    ProtectedEndPoints.GetCurrentProviderLocation,
    request
  );

export default {
  getUserDashboardGrid,
  getViewUploadsInfo,
  uploadFiles,
  downloadFiles,
  downloadMultipleFiles,
  getCreateCareProfile,
  getCreateRequestInfoForMe,
  updateCareProfile,
  uploadAudioVideoForChat,
  getLoggedInUserId,
  getAllZipCodes,
  getCurrentProviderLocation,
};
