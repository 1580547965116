import { Box, Button, Modal, Typography } from "@mui/material";
import { WarningIcon } from "../../assests/images";

type ComponentProps = {
  isModalOpen: boolean;
  handleOnClickCloseModal: any;
  handleOnClickOk: any;
  warningMessage: string;
  title: string;
  okButtonText: string | undefined;
  closeButtonText: string | undefined;
  warningIcon?: any;
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 556,
  borderRadius: "10px",
  bgcolor: "background.paper",
  padding: "76px 74px 70px",
  textAlign: "center",
  "@media (max-width:599px)": {
    width: "calc(100% - 30px)",
    padding: "30px",
  },
};

export function WarningModal(props: ComponentProps) {
  // Page level interface

  // Usestates

  // Extract Props
  const {
    isModalOpen,
    handleOnClickCloseModal,
    title,
    warningMessage,
    okButtonText,
    closeButtonText,
    handleOnClickOk,
    warningIcon,
  } = props;

  // Events and functions
  const handleOnClickOkButton = () => {
    handleOnClickOk();
    handleOnClickCloseModal();
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleOnClickCloseModal}
        className="reset-modal"
      >
        <Box sx={style}>
          <img
            src={warningIcon ? warningIcon : WarningIcon}
            alt="warning"
            className="reset-img"
          />
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body1">{warningMessage}</Typography>
          <div className="warning-modal-btn-group">
            {okButtonText && (
              <Button variant="contained" onClick={handleOnClickOkButton}>
                {okButtonText}
              </Button>
            )}
            {closeButtonText && (
              <Button variant="outlined" onClick={handleOnClickCloseModal}>
                {closeButtonText}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
