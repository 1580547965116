import { Button, Grid, Hidden } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { ArrowBack, LoginBanner } from "../../assests/images";
import { AccountFooter } from "../../components/account/footer";
import { ForgotPasswordForm } from "../../components/account/forgot-password-form";
import { AccountHeader } from "../../components/account/header";
import { LoginForm } from "../../components/account/login-form";
import { LightDarkButton } from "../../components/common/light-dark-button";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";

const LoginPage: React.FC = () => {
  // Page level local variables
  const navigate = useNavigate();
  const { state } = window.history;

  // useStates
  const [isForgotPwdOpen, setIsForgotPwdOpen] = React.useState(false);
  const [isHaloMember, setIsHaloMember] = React.useState(false);
  const [isLoginOpen, setIsLoginOpen] = React.useState(false);

  // Handled events and functions
  const handleClickForgotPassword = () => setIsForgotPwdOpen(true);
  const handleClickBackToLogin = () => setIsForgotPwdOpen(false);

  React.useEffect(() => {
    if (state) {
      console.log(state);
      if (state.usr?.isLogin) setIsLoginOpen(true);
    }
  }, []);

  return (
    <>
      <section className="login-wrapper">
        <Grid container className="login-container">
          {/* <Hidden mdDown> */}
          {/* <Grid item xs={12} md={5} laptop={5} xl={5} className="login-banner">
            <img src={LoginBanner} alt="Banner" />
          </Grid> */}
          {/* </Hidden> */}
          <Grid
            item
            xs={12}
            // md={7}
            // laptop={7}
            // xl={7}
            className="login-content-container"
          >
            <LightDarkButton />
            <div className="login-content">
              <AccountHeader />
              {isLoginOpen ? (
                <>
                  <Button
                    onClick={() => {
                      setIsLoginOpen(false);
                      setIsForgotPwdOpen(false);
                    }}
                    style={{ maxWidth: 90, textAlign: "center" }}
                    className="back-login"
                  >
                    <img src={ArrowBack} alt="arrow" />
                    Back
                  </Button>
                  <div className="login-content-form">
                    {isForgotPwdOpen ? (
                      <ForgotPasswordForm
                        isHaloMember={isHaloMember}
                        handleClickBackToLogin={handleClickBackToLogin}
                      />
                    ) : (
                      <LoginForm
                        isHaloMember={isHaloMember}
                        handleClickForgotPassword={handleClickForgotPassword}
                      />
                    )}
                  </div>
                </>
              ) : (
                <Grid
                  container
                  sx={{ textAlign: "center", rowGap: 3 }}
                  columnSpacing={{ sm: 2, lg: 3 }}
                >
                  <Grid item xs={12} sm={12}>
                    <Tooltip title="Submit Request">
                      <Button
                        onClick={() => {
                          navigate(AppRoutings.CreateRequest);
                        }}
                        variant="contained"
                        className="login-page-buttons"
                      >
                        Submit a Request
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Tooltip title="Registered Patients">
                      <Button
                        onClick={() => {
                          setIsLoginOpen(true);
                          setIsHaloMember(false);
                        }}
                        style={{ backgroundColor: "#0e1555" }}
                        variant="contained"
                        className="login-page-buttons"
                      >
                        Registered Patients
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Tooltip title="HALO Members">
                      <Button
                        href="https://care2.doctorstoyou.com/Account/HaloLogin"
                        style={{ backgroundColor: "#ffa500" }}
                        className="login-page-buttons"
                        variant="contained"
                      >
                        HALO Members
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              <AccountFooter />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default LoginPage;
