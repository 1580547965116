import React from "react";
import {
  Button,
  TableCell,
  TableRow,
  ButtonProps,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Tooltip,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ClockPrimaryIcon,
  DoctorLightIcon,
  DoctorPrimaryIcon,
  PatientLightIcon,
  PatientPrimaryIcon,
  PhysicianIcon,
  RegionIcon,
  StatusCheckPrimaryIcon,
} from "../../assests/images";
import { IDashboardRequest } from "../../utility/interfaces/dashboard/dashboard-info";
import { ISearchCriteria } from "../../utility/interfaces/common/search-criteria";
import { AppRoutings } from "../../utility/enums/app-routings";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Constants } from "../../utility/enums/constants";
import { RequestStatus } from "../../utility/enums/request-status";

type ComponentProps = {
  row: IDashboardRequest;
  isSmallDevice: boolean;
  handleOpenChatBox: any;
};

interface ExpandMoreProps extends ButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return (
    <Button {...other} disableFocusRipple disableElevation disableRipple />
  );
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function TableRowData(props: ComponentProps) {
  // useStates
  const [expanded, setExpanded] = React.useState(false);

  // Extract Props
  const { row, isSmallDevice, handleOpenChatBox } = props;

  // Handled events and functions
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // Page level local variables
  const navigate = useNavigate();

  let className = "bg-white";
  let btnClass = "";
  if (
    moment(row.createdDate).format("DD-MM-YYYY") ===
    moment(new Date()).format("DD-MM-YYYY")
  ) {
    className = "bg-patient";
    btnClass = "btn-light";
  }

  return (
    <>
      {!isSmallDevice ? (
        <>
          <TableRow key={row.requestId} className={className}>
            {/* <TableCell
              component="th"
              style={{ minWidth: 130 }}
              scope="row"
              className="wrap"
            >
              {row.clientMember}
            </TableCell> */}
            <TableCell
              style={{ minWidth: 150 }}
              component="th"
              scope="row"
              className="wrap"
            >
              {moment(row.createdDate).format("MMM D, YYYY")}
            </TableCell>
            {/* <TableCell
              style={{ minWidth: 130 }}
              component="th"
              scope="row"
              className="wrap"
            >
              {row.confirmation}
            </TableCell> */}
            {/* <TableCell
              style={{ minWidth: 130 }}
              component="th"
              scope="row"
              className="wrap"
            >
              {row.providerName}
            </TableCell> */}
            {/* <TableCell
              style={{ minWidth: 170 }}
              component="th"
              scope="row"
              className="wrap"
            >
              {row.concludedDate}
            </TableCell> */}
            <TableCell
              style={{ minWidth: 160 }}
              className="wrap"
              component="th"
              scope="row"
            >
              {row.currentStatus}
            </TableCell>
            {/* <TableCell
              style={{ minWidth: 130 }}
              align="center"
              component="th"
              scope="row"
            >
              <span className="table-cell-title">Report</span>
              {row.finalReportUrl && row.finalReportUrl?.trim() !== "" && (
                <Button
                  id="table-button"
                  className="action-btn action-btn-primary"
                >
                  View
                </Button>
              )}
            </TableCell> */}
            <TableCell
              style={{ minWidth: 130 }}
              align="center"
              component="th"
              scope="row"
            >
              <span className="table-cell-title">Report</span>
              <div className="table-cell-btn-group">
                {row.status !== RequestStatus.Unassigned && (
                  <>
                    <Button
                      className={"action-btn action-btn-primary " + btnClass}
                      variant="outlined"
                      onClick={() =>
                        handleOpenChatBox(
                          row.userId,
                          row.requestId,
                          Constants.AdminId,
                          "Admin",
                          row.confirmationNumber,
                          true
                        )
                      }
                    >
                      <img
                        src={
                          btnClass != "" ? PatientLightIcon : PatientPrimaryIcon
                        }
                        alt=""
                      />
                      Admin {row.adminCount > 0 ? `(${row.adminCount})` : ""}
                    </Button>
                    <Tooltip title={row.physicianName}>
                      <Button
                        className={"action-btn action-btn-primary " + btnClass}
                        disabled={!row.physicianId}
                        variant="outlined"
                        onClick={() =>
                          handleOpenChatBox(
                            row.userId,
                            row.requestId,
                            row.physicianId,
                            row.physicianName,
                            row.confirmationNumber,
                            false
                          )
                        }
                      >
                        <img
                          src={
                            btnClass != "" ? DoctorLightIcon : DoctorPrimaryIcon
                          }
                          alt=""
                        />
                        Provider{" "}
                        {row.physicianCount > 0
                          ? `(${row.physicianCount})`
                          : ""}
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
            </TableCell>
            <TableCell
              style={{ minWidth: 130 }}
              align="center"
              component="th"
              scope="row"
            >
              <span className="table-cell-title">Documents</span>
              <div className="table-cell-btn-group">
                {row.status === RequestStatus.Closed &&
                  row.completedByPhysician === 0 && (
                    <Button
                      id="table-button"
                      className={"action-btn action-btn-primary " + btnClass}
                      onClick={() =>
                        navigate(
                          AppRoutings.ViewDocuments.replace(
                            ":requestId",
                            row.queryString
                          )
                        )
                      }
                    >
                      ({row.fileCount}) Documents
                    </Button>
                  )}
                {row.isTrackPhysician === 1 && (
                  <Button
                    id="table-button"
                    className={"action-btn action-btn-primary " + btnClass}
                    onClick={() => {
                      navigate(
                        AppRoutings.TrackProvider.replace(
                          ":requestId",
                          row.queryString
                        )
                      );
                      // console.log("Track provider not implemented yet!!!");
                    }}
                  >
                    Track Provider
                  </Button>
                )}
              </div>
            </TableCell>
          </TableRow>
        </>
      ) : (
        // Mobile view
        <Card className="bg-white">
          <div className="card-container">
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {/* <CardHeader
                title={
                  <React.Fragment>
                    <span>{row.clientName}</span>
                  </React.Fragment>
                }
              /> */}
              <CardContent className="card-header-content">
                <Typography>
                  <img src={ClockPrimaryIcon} alt="time" />
                  Created Date:&nbsp;
                  <span>
                    &nbsp;{moment(row.createdDate).format("MMM D, YYYY")}&nbsp;
                  </span>
                </Typography>
              </CardContent>
            </ExpandMore>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                {/* <div>
                  <span>
                    <img src={StatusCheckPrimaryIcon} alt="check" />
                  </span>
                  <Typography variant="h6">
                    Confirmation: &nbsp; <span>{row.confirmationNumber}</span>
                  </Typography>
                </div> */}
                <div>
                  <span>
                    <img src={DoctorPrimaryIcon} alt="calender" />
                  </span>
                  <Typography variant="h6">
                    Provider: &nbsp;
                    <span>{row.physicianName}</span>
                  </Typography>
                </div>
                {/* <div>
                  <span>
                    <img src={PhysicianIcon} alt="check" />
                  </span>
                  <Typography variant="h6">
                    <span>ConcludedDate: &nbsp;</span>
                    <span>{row.concludedDate}</span>
                  </Typography>
                </div> */}
                <div>
                  <span>
                    {/* <img src={RegionIcon} alt="check" /> */}
                    <img src={StatusCheckPrimaryIcon} alt="check" />
                  </span>
                  <Typography variant="h6">
                    CurrentStatus: &nbsp; <span>{row.currentStatus}</span>
                  </Typography>
                </div>
                {row.status !== RequestStatus.Unassigned && (
                  <div className="table-cell-btn-group table-btn-group-sm">
                    <Button
                      variant="outlined"
                      disabled={row.physicianId === 0}
                      onClick={() =>
                        handleOpenChatBox(
                          row.userId,
                          row.requestId,
                          row.physicianId,
                          row.physicianName,
                          row.confirmationNumber,
                          false
                        )
                      }
                    >
                      <img src={DoctorPrimaryIcon} alt="patient" />
                      Chat with{" "}
                      {row.physicianId > 0
                        ? `${row.physicianName}`
                        : "Provider"}{" "}
                      {row.physicianCount > 0 ? `(${row.physicianCount})` : ""}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleOpenChatBox(
                          row.userId,
                          row.requestId,
                          Constants.AdminId,
                          "Admin",
                          row.confirmationNumber,
                          true
                        )
                      }
                    >
                      <img src={PatientPrimaryIcon} alt="patient" />
                      Chat with Admin{" "}
                      {row.adminCount > 0 ? `(${row.adminCount})` : ""}
                    </Button>
                  </div>
                )}

                <div className="table-cell-btn-group table-btn-group-sm">
                  {row.status === RequestStatus.Closed &&
                    row.completedByPhysician === 0 && (
                      <Button
                        variant="outlined"
                        onClick={() =>
                          navigate(
                            AppRoutings.ViewDocuments.replace(
                              ":requestId",
                              row.queryString
                            )
                          )
                        }
                      >
                        ({row.fileCount}) Documents
                      </Button>
                    )}
                  {/* <Button variant="outlined">View Reports</Button> */}
                </div>
              </CardContent>
            </Collapse>
          </div>
        </Card>
      )}
    </>
  );
}
