import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppRoutings } from "../../utility/enums/app-routings";
import tokenManager from "./token-manager";
type ComponentProps = {
	element: any;
};
export const UnProtectedRoute = (props: ComponentProps) => {
	// Extract Props
	const { element } = props;
	const sessionToken = tokenManager.getToken();
	return !sessionToken ? (
		element || <Outlet />
	) : (
		<Navigate
			to={AppRoutings.Dashboard}
			replace
		/>
	);
};
