import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { UploadcloudIcon } from "../../assests/images";

type ComponentProps = {
  onSelectNewFiles: any;
  isMultiple: boolean;
  accept: string;
  fileName: string;
};
export function UploadDocument(props: ComponentProps) {
  // useStates
  const { onSelectNewFiles, isMultiple, accept, fileName } = props;

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="upload-file"
          type="text"
          disabled
          value={fileName}
          className="upload-input"
          placeholder="Select File"
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="Click Here To Upload">
                <Button
                  variant="contained"
                  component="label"
                  disableElevation
                  disableFocusRipple
                  disableRipple
                >
                  <img src={UploadcloudIcon} alt="upload" />
                  <span>Upload</span>
                  <input
                    onChange={onSelectNewFiles}
                    hidden
                    accept={accept}
                    multiple={isMultiple}
                    type="file"
                  />
                </Button>
              </Tooltip>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
    </>
  );
}
